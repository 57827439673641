import client from "../client";
const endpoint = "/user/auth";

const cachePhone = ({ phone, password }) =>
	client.post(`${endpoint}/cachePhone`, { phone, password });

const createAccount = ({ phone, code }) =>
	client.post(`${endpoint}/createAccount`, { phone, code });

const login = ({ phone, password }) =>
	client.post(`${endpoint}/login`, { phone, password });

const forgotPasswordCachePhone = ({ phone, password }) =>
	client.post(`${endpoint}/forgotPasswordCachePhone`, { phone, password });

const forgotPasswordVerifyCode = ({ phone, code }) =>
	client.post(`${endpoint}/forgotPasswordVerifyCode`, { phone, code });

const auth = {
	cachePhone,
	createAccount,
	login,
	forgotPasswordCachePhone,
	forgotPasswordVerifyCode,
};
export default auth;
