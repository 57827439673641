// File: User auth logging in
// Default
import React, { useEffect, useState } from "react";
// Nav
import { useParams } from "react-router-dom";
// API
import usersApi from "../../api/user/main";
// Libraries
import { useToast } from "@chakra-ui/react";
import LoadingWrapper from "../../helper/loadingWrapper";
export default function EmailVerification() {
	// Default
	const toast = useToast();
	const [loading, setLoading] = useState(true);
	// Nav
	const { email, userID, code } = useParams();
	// Requests
	const verifyEmail = async () => {
		setLoading(true);
		const result = await usersApi.profile.verifyEmail({
			email,
			userID,
			code,
		});
		if (result.ok) {
			toast({
				title: "Email Verified.",
				description: "Refresh Your Emails Page to see the changes.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
		setLoading(false);
	};
	useEffect(() => {
		verifyEmail();
	}, []);
	return (
		<div id='app-center' className='login'>
			<LoadingWrapper loading={loading}>
				<h2 className='center'>Email Verification</h2>
			</LoadingWrapper>
		</div>
	);
}
