// File: Reusable Card Body
// Default
import React from "react";
// Libraries
import Linkify from "react-linkify";

export default function CardBody({ title, body, isTruncated }) {
	return (
		<div className='card-body'>
			<Linkify>
				<h4>{title}</h4>
				<p>
					{body}
					{isTruncated ? "..." : ""}
				</p>
			</Linkify>
		</div>
	);
}
