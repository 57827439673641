// File: First view for creating a post
// Default
import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../providers/Provider.jsx";
// Nav
import { useNavigate } from "react-router-dom";
// API
import postsApi from "../../api/posts/main.js";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper.js";
// Components
import Tabs from "../../components/tabs.js";
import CardType from "../../components/cards/cardComponents/cardType.js";
// Assets
import DeleteIcon from "@mui/icons-material/Delete";
// Libraries
import { useToast } from "@chakra-ui/react";

export default function BeHeard() {
	// Default
	const { userID } = useContext(Context);
	const toast = useToast();
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const navigate = useNavigate();
	// Specific
	const [drafts, setDrafts] = useState([]);
	const [posterID, setPosterID] = useState(userID);
	const [isOrg, setIsOrg] = useState(false);
	const buttons = [
		{
			title: "NEWPOST",
			text: "New Post",

			class: "poll",
			subTitle:
				"Poll your community about issues or suggest a solution to a problem",
			description:
				"Create a post with multiple options that people can vote for",
			onClick: () => newPostDraft(),
			visible: true,
		},
	];
	// Requests
	const getDrafts = async () => {
		setLoading(true);
		const result = await postsApi.newFeeds.drafts({
			posterID,
			isOrg,
		});
		if (!result.ok) {
		} else {
			setDrafts(result.data);
		}
		setLoading(false);
	};
	const newPostDraft = async () => {
		navigate(`/post/create/${null}`);
	};

	const deleteDraft = async (postID, type) => {
		const result = await postsApi.options.deleteDraft({ postID, type });
		if (result.ok) {
			toast({
				title: "",
				description: result.data,
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
			getDrafts();
		}
	};
	// Functions
	function handleDraftCardClick(type, id) {
		navigate(`/post/create/${id}`);
	}
	// Use Effects
	useEffect(() => {
		if (posterID.length === 0) {
			setPosterID(userID);
		} else {
			getDrafts();
		}
	}, [posterID]);

	return (
		<>
			<div id='app-center' className='be-heard-screen'>
				<div className='post-types '>
					<Tabs tabs={buttons} large />
				</div>
				<div className='section-card'>
					<LoadingWrapper loading={loading}>
						{drafts.length > 0 && (
							<div className='drafts'>
								<h3>Drafts</h3>
								{drafts.map(function (item, index) {
									return (
										<div
											className='draft-card std-card click'
											onClick={() => {
												handleDraftCardClick(item.type, item.postInfo.postID);
											}}
										>
											<CardType type={item.type} size={"small"} />
											<div>
												<p>{item.postInfo.title}</p>
												<p>{item.dateInfo.fromNow}</p>
												<p>
													Posting to <i>{item.communityInfo.name}</i>
												</p>
											</div>
											<button
												className='edit'
												onClick={(e) => {
													e.stopPropagation();
													deleteDraft(item.postInfo.postID, item.type);
												}}
											>
												<DeleteIcon />
											</button>
										</div>
									);
								})}
							</div>
						)}
					</LoadingWrapper>
				</div>
			</div>
		</>
	);
}
