// File: Creating a post left margin, shows groups you can post to
// Default
import React, { useEffect, useState } from "react";
// API
import communityApi from "../../../api/community/main";
// Helper
import LoadingWrapper from "../../../helper/loadingWrapper";
// Components
import CommunityCard from "../../cards/communityCard";
// Assets
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

export default function CreateLeft({
	communityID,
	setCommunityID,
	communityInfo,
	setCommunityInfo,
	isAdmin,
}) {
	// Loading
	const [loading, setLoading] = useState(false);
	// Specific
	const [yourGroups, setYourGroups] = useState([]);
	const [favorites, setFavorites] = useState([]);
	const [expanded, setExpanded] = useState(false);
	// Requests
	const getDrawerGroups = async () => {
		setLoading(true);

		if (!isAdmin) {
			const result = await communityApi.feed.leftGroups();
			if (result.ok) {
				setYourGroups(result.data.yourGroups);
				setFavorites(result.data.favorites);
			}
		} else {
			const result = await communityApi.feed.postAsGroups();
			if (result.ok) {
				setYourGroups(result.data);
			}
		}

		setLoading(false);
	};
	// Use Effects
	useEffect(() => {
		getDrawerGroups();
	}, []);
	return (
		<LoadingWrapper loading={loading}>
			{yourGroups.length > 0 && (
				<div className='section-card posting-as'>
					<button
						onClick={() => {
							setExpanded(!expanded);
						}}
						className='selected'
					>
						<h5>Share Post To:</h5>
						<div className='flex-between'>
							<CommunityCard
								groupID={communityInfo.communityID}
								name={communityInfo.name}
								pic={communityInfo.pic}
								subGroups={[]}
								isAdmin={false}
								onClick={(groupID, name, pic) => {
									setExpanded(!expanded);
								}}
							/>
							{!expanded && <ArrowDropDown />}
							{expanded && <ArrowDropUp />}
						</div>
					</button>

					{expanded && (
						<>
							<CommunityCard
								groupID={"Global"}
								name={"Global"}
								pic={null}
								subGroups={[]}
								isAdmin={false}
								selected={communityID === "Global"}
								onClick={(groupID, name, pic) => {
									setCommunityInfo({
										communityID: groupID,
										name,
										pic,
									});
									setCommunityID(groupID);
									setExpanded(!expanded);
								}}
							/>
							{!isAdmin && <h5>Your Favorites</h5>}
							{favorites.map(function (item, index) {
								return (
									<>
										<CommunityCard
											name={item.name}
											pic={item.pic}
											subGroups={item.subGroups}
											joined={item.joined}
											isAdmin={item.isAdmin}
											groupID={item._id}
											onClick={(groupID, name, pic) => {
												setCommunityInfo({
													communityID: groupID,
													name,
													pic,
												});
												setCommunityID(groupID);
												setExpanded(!expanded);
											}}
											selected={communityID === item._id.toString()}
										/>
									</>
								);
							})}
							<h5>Your Communities</h5>

							{yourGroups.map(function (item, index) {
								return (
									<>
										<CommunityCard
											name={item.name}
											pic={item.pic}
											subGroups={item.subGroups}
											joined={item.joined}
											isAdmin={item.isAdmin}
											groupID={item._id}
											onClick={(groupID, name, pic) => {
												setCommunityInfo({
													communityID: groupID,
													name,
													pic,
												});
												setCommunityID(groupID);
												setExpanded(!expanded);
											}}
											selected={communityID === item._id.toString()}
										/>
									</>
								);
							})}
						</>
					)}
				</div>
			)}
		</LoadingWrapper>
	);
}
