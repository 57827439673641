import client from "../client";
const endpoint = "/community/members";

const inviteMembers = ({ groupID, userIDs }) =>
	client.post(`${endpoint}/inviteMembers`, { groupID, userIDs });

const searchUsers = ({ searchField, communityID }) =>
	client.post(`${endpoint}/searchInviteMembers`, { searchField, communityID });

const acceptInvite = (communityID) =>
	client.post(`${endpoint}/acceptInvite`, { communityID });

const rejectInvite = (communityID) =>
	client.post(`${endpoint}/rejectInvite`, { communityID });

// TODO
const kick = ({ userID, communityID }) =>
	client.post(`${endpoint}/kick`, { userID, communityID });

// TODO
const addAdmin = ({ userID, communityID }) =>
	client.post(`${endpoint}/addAdmin`, { userID, communityID });

// TODO
const removeAdmin = ({ userID, communityID }) =>
	client.post(`${endpoint}/removeAdmin`, { userID, communityID });

// TODO
const requestJoin = (communityID) =>
	client.post(`${endpoint}/requestJoin`, { communityID });

// TODO
const acceptRequest = ({ userID, communityID }) =>
	client.post(`${endpoint}/acceptRequest`, { userID, communityID });

// TODO
const rejectRequest = ({ userID, communityID }) =>
	client.post(`${endpoint}/rejectRequest`, { userID, communityID });

const getAdmins = (communityID) =>
	client.post(`${endpoint}/getAdmins`, { communityID });
const getMembers = (communityID) =>
	client.post(`${endpoint}/getMembers`, { communityID });

const members = {
	inviteMembers,
	acceptInvite,
	kick,
	addAdmin,
	removeAdmin,
	requestJoin,
	acceptRequest,
	rejectRequest,
	getAdmins,
	getMembers,
	searchUsers,
	rejectInvite,
};
export default members;
