// File: Reusable member card
// Default
import React from "react";
// Nav
import { useNavigate } from "react-router-dom";
// Libraries
import {
	Avatar,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	useToast,
} from "@chakra-ui/react";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { MoreVert } from "@mui/icons-material";
import communityApi from "../../api/community/main";
export default function MemberCard({
	name,
	pic,
	username,
	cardUserID,
	isOrg,
	isAdmin,
	userIsAdmin,
	communityID,
}) {
	// Nav
	const navigate = useNavigate();
	const toast = useToast();
	// functions
	// promote/demote
	const promoteToAdmin = async () => {
		const result = await communityApi.members.addAdmin({
			userID: cardUserID,
			communityID,
		});
		if (result.ok) {
			toast({
				title: "Promoted!",
				description: "This will take a few moments to update.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};
	const demoteFromAdmin = async () => {
		const result = await communityApi.members.removeAdmin({
			userID: cardUserID,
			communityID,
		});
		if (result.ok) {
			toast({
				title: "Demoted!",
				description: "This will take a few moments to update.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};
	// kick
	const kickMember = async () => {
		const result = await communityApi.members.kick({
			userID: cardUserID,
			communityID,
		});
		if (result.ok) {
			toast({
				title: "Kicked!",
				description: "This will take a few moments to update.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};
	// Popover
	const PopOverButton = ({ text, icon, clickHandler }) => {
		return (
			<button
				className='card-list-item'
				onClick={(e) => {
					e.stopPropagation();
					clickHandler();
				}}
			>
				{icon}
				<p>{text}</p>
			</button>
		);
	};
	const Options = () => {
		return (
			<div>
				<Popover>
					<PopoverTrigger>
						<button
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<MoreVert />
						</button>
					</PopoverTrigger>
					<Portal>
						<PopoverContent>
							<PopoverArrow />
							<PopoverHeader>Member Options</PopoverHeader>
							<PopoverBody>
								<div>
									<PopOverButton
										text={
											!userIsAdmin ? "Promote to Admin" : "Demote from Admin"
										}
										icon={<PersonRemoveIcon />}
										clickHandler={
											userIsAdmin ? demoteFromAdmin : promoteToAdmin
										}
									/>
									<PopOverButton
										text={"Kick Member"}
										icon={<PersonRemoveIcon />}
										clickHandler={kickMember}
									/>
								</div>
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			</div>
		);
	};
	return (
		<div className={`member-card`}>
			<button
				onClick={(e) => {
					e.stopPropagation();
					navigate(
						`/profile/${cardUserID}/${isOrg !== undefined ? isOrg : "false"}`
					);
				}}
			>
				<Avatar alt={name} src={pic} className='avatar' />
			</button>
			<div className='content'>
				<div>
					<h6>{name}</h6>
					<h6 className='secondary'>@{username || "user"}</h6>
				</div>
			</div>
			{isAdmin && (
				<div className='option-btn'>
					<Options />
				</div>
			)}
		</div>
	);
}
