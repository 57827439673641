// File: Invite members for communities
// Default
import React, { useState } from "react";
// API
import communityApi from "../../api/community/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import CommunityCard from "../cards/communityCard";
import MemberCard from "../cards/memberCard";
// Assets
import { Add } from "@material-ui/icons";
// Libraries
import {
	Button,
	Input,
	InputGroup,
	InputRightElement,
	useToast,
} from "@chakra-ui/react";
import defaultApi from "../../api/default/main";

export default function InviteMembers({ communityID, parentInfo }) {
	const toast = useToast();
	// Loading
	const [loading, setLoading] = useState(false);
	// Specific
	const [searchField, setSearchField] = useState("");
	const [byEmail, setByEmail] = useState([]);
	const [byPhone, setByPhone] = useState([]);
	const [byName, setByName] = useState([]);
	const [byUsername, setByUsername] = useState([]);
	// Requests
	const handleSubmit = async () => {
		setLoading(true);
		const result = await defaultApi.search.searchMembers({
			searchField,
			communityID,
		});
		if (result.ok) {
			let data = result.data;
			setByPhone(data.byPhone);
			setByEmail(data.byEmail);
			setByName(data.byName);
			setByUsername(data.byUsername);
		}
		setLoading(false);
	};
	const inviteMember = async (userID) => {
		const result = await communityApi.members.inviteMembers({
			groupID: communityID,
			userIDs: [{ userID }],
		});
		if (result.ok) {
			toast({
				title: "Successfully Invited.",
				description: "An invitation will be waiting for them in their inbox.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};

	return (
		<div className='invite-members '>
			{parentInfo.communityID.length > 0 && (
				<>
					<p>You can only invite members of:</p>
					<CommunityCard
						groupID={parentInfo.communityID}
						name={parentInfo.name}
						pic={parentInfo.pic}
						subGroups={[]}
						isAdmin={false}
						onPress={(groupID) => {}}
					/>
				</>
			)}
			<h3 className='left'>Invite Members</h3>
			<div className='text-field-wrapper'>
				<InputGroup>
					<Input
						variant='filled'
						value={searchField}
						onChange={(e) => {
							setSearchField(e.target.value);
						}}
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								handleSubmit();
							}
						}}
						placeholder='Search by name, email, and phone'
					/>
					<InputRightElement>
						<Button
							variant='contained'
							disabled={searchField.length === 0}
							onClick={() => handleSubmit()}
							isLoading={loading}
						>
							<Add className='icon-white' />
						</Button>
					</InputRightElement>
				</InputGroup>
			</div>
			<LoadingWrapper loading={loading}>
				{byEmail.length > 0 && (
					<>
						<h3>By Email</h3>
						{byEmail.map(function (item, index) {
							return (
								<div className='invite-container'>
									<MemberCard
										name={item.name}
										pic={item.profilePic}
										cardUserID={item._id}
										username={item.username}
									/>
									<Button
										variant='text'
										onClick={() => {
											inviteMember(item._id);
										}}
									>
										Invite
									</Button>
								</div>
							);
						})}
					</>
				)}
				{byPhone.name && (
					<div className='invite-container'>
						<h3>By Phone</h3>
						<MemberCard
							name={byPhone.name}
							pic={byPhone.profilePic}
							cardUserID={byPhone._id}
							username={byPhone.username}
						/>
						<Button
							variant='text'
							onClick={() => {
								inviteMember(byPhone._id);
							}}
						>
							Invite
						</Button>
					</div>
				)}
				{byUsername.length > 0 && (
					<>
						<h3>By Username</h3>
						{byUsername.map(function (item, index) {
							return (
								<div className='invite-container'>
									<MemberCard
										name={item.name}
										pic={item.profilePic}
										cardUserID={item._id}
										username={item.username}
									/>
									<Button
										variant='text'
										onClick={() => {
											inviteMember(item._id);
										}}
									>
										Invite
									</Button>
								</div>
							);
						})}
					</>
				)}
				{byName.length > 0 && (
					<>
						<h3>By Name</h3>
						{byName.map(function (item, index) {
							return (
								<div className='invite-container'>
									<MemberCard
										name={item.name}
										pic={item.profilePic}
										cardUserID={item._id}
										username={item.username}
									/>
									<Button
										variant='text'
										onClick={() => {
											inviteMember(item._id);
										}}
									>
										Invite
									</Button>
								</div>
							);
						})}
					</>
				)}
			</LoadingWrapper>
		</div>
	);
}
