// File: Button to go through account activation api
// Default
import React from "react";
// Nav
import { useLocation } from "react-router-dom";
// API
import paymentsApi from "../../api/payments";
// Libraries
import { Button } from "@chakra-ui/react";

export default function AccountActivation() {
	// Nav
	const search = useLocation().search;
	// Requests
	const activate = async () => {
		const result = await paymentsApi.activateAccount();
		if (result.ok) {
			window.open(result.data);
		}
	};

	return (
		<div id='app-center'>
			<h1>Account Activation</h1>
			{search === "?success" ? <p>Success</p> : <p>Fail</p>}
			<Button onClick={activate}>Activate Account</Button>
		</div>
	);
}
