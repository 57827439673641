import client from "../client";
const endpoint = "/community/data";

const groupInfo = (communityID) =>
	client.post(`${endpoint}/groupInfo`, { communityID });

const groupPage = (communityID) =>
	client.post(`${endpoint}/focusCommunity`, { communityID });

const data = {
	groupInfo,
	groupPage,
};
export default data;
