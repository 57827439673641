// File:
// Default
import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// API
import communityApi from "../../../api/community/main";
// Helper
import LoadingWrapper from "../../../helper/loadingWrapper";
// Components
import CommunityCard from "../../cards/communityCard";

export default function HomeRight() {
	// Default
	const { userID } = useContext(Context);
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const navigate = useNavigate();
	// Specific
	const [popularGroups, setPopularGroups] = useState([]);
	const [byEmailGroups, setByEmailGroups] = useState([]);
	const [popularSubGroups, setPopularSubGroups] = useState([]);
	const [featured, setFeatured] = useState([]);
	// Requests
	const getDrawerGroups = async () => {
		setLoading(true);
		const result = await communityApi.feed.rightGroups();
		if (result.ok) {
			setPopularGroups(result.data.popular);
			setByEmailGroups(result.data.byEmail);
			setPopularSubGroups(result.data.popularSub);
			setFeatured(result.data.featured);
		}
		setLoading(false);
	};
	// Functions
	function selectGroup(groupID) {
		navigate(`/feed/${groupID}`);
	}
	// Use Effects
	useEffect(() => {
		getDrawerGroups();
	}, [userID]);

	return (
		<div id='home-right' className='section-card'>
			<a href='https://quae.app/communities' target='_blank' rel='noreferrer'>
				What is Quae?
			</a>
			<LoadingWrapper loading={loading}>
				<div className='group-list-container scroll-auto'>
					{featured.length > 0 ? (
						<>
							<h4>Featured</h4>

							{featured.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
										onClick={selectGroup}
									/>
								);
							})}
						</>
					) : (
						<></>
					)}
					{byEmailGroups.length > 0 ? (
						<>
							<h4>Based on your Profile</h4>

							{byEmailGroups.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
										onClick={selectGroup}
									/>
								);
							})}
						</>
					) : (
						<></>
					)}

					{popularSubGroups.length > 0 ? (
						<>
							<h4>Based on your Community</h4>

							{popularSubGroups.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
										onClick={selectGroup}
									/>
								);
							})}
						</>
					) : (
						<></>
					)}

					{popularGroups.length > 0 ? (
						<>
							<h4>Suggested Communities</h4>

							{popularGroups.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
										onClick={selectGroup}
									/>
								);
							})}
						</>
					) : (
						<h3>No Popular Groups</h3>
					)}
				</div>
			</LoadingWrapper>
		</div>
	);
}
