// File: See all of your blocked users
// Default
import React, { useEffect, useState } from "react";
// API
import usersApi from "../../api/user/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import MemberCard from "../cards/memberCard";
// Assets
import { MoreVert } from "@material-ui/icons";

export default function BlockedUsers({ blockedUsersRef }) {
	// Loading
	const [loading, setLoading] = useState(true);
	// Specific
	const [blockedUsers, setBlockedUsers] = useState([]);
	// Requests
	const getBlockedUsers = async () => {
		setLoading(true);
		const result = await usersApi.data.blockedUsers();
		if (result.ok) {
			setBlockedUsers(result.data);
		}
		setLoading(false);
	};
	// Use Effects
	useEffect(() => {
		getBlockedUsers();
	}, []);
	return (
		<div id='blocked-users' ref={blockedUsersRef} className='section-card'>
			<h3>Blocked users</h3>
			<LoadingWrapper loading={loading}>
				{blockedUsers.length === 0 && (
					<div>
						<h6>No blocked users</h6>
						<p>
							Click <MoreVert /> on someone's profile to block them
						</p>
					</div>
				)}
				{blockedUsers.map(function (item, idx) {
					return (
						<li key={idx}>
							<MemberCard
								name={item.name}
								pic={item.pic}
								username={item.username}
								cardUserID={item.userID}
								isOrg={item.isOrg}
							/>
						</li>
					);
				})}
			</LoadingWrapper>
		</div>
	);
}
