import client from "../client";
const endpoint = "/community/settings";

const uploadName = ({ name, communityID }) =>
	client.post(`${endpoint}/uploadName`, { name, communityID });
const uploadBio = ({ purpose, communityID }) =>
	client.post(`${endpoint}/uploadBio`, { purpose, communityID });
const uploadPic = ({ formData, communityID }) =>
	client.post(`${endpoint}/uploadPic/${communityID}`, formData);

const toggleVisible = (communityID) =>
	client.post(`${endpoint}/toggleVisible`, { communityID });

const feed = {
	uploadName,
	uploadBio,
	toggleVisible,
	uploadPic,
};
export default feed;
