import client from "../client";
const endpoint = "/posts/newFeeds";

const singleFeed = ({ communityID, skip }) =>
	client.post(`${endpoint}/singleFeed`, { communityID, skip });

const allFeed = (skip) => client.post(`${endpoint}/allFeed`, { skip });

const profileAll = ({ userID, isOrg, skip }) =>
	client.post(`${endpoint}/profileAll`, { userID, isOrg, skip });

const discussions = ({ parentType, identifier }) =>
	client.post(`${endpoint}/discussions`, { parentType, identifier });

const drafts = ({ posterID, isOrg }) =>
	client.post(`${endpoint}/drafts`, { posterID, isOrg });

const feeds = {
	singleFeed,
	allFeed,
	profileAll,
	discussions,
	drafts,
};
export default feeds;
