// File: Community Full Screen
// Default
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useParams } from "react-router-dom";
// API
import communityApi from "../../api/community/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import AdminPreferences from "../../components/community/adminPreferences";
import UploadPicture from "../../components/uploadPicture";
import OpenInApp from "../../components/post/openInApp";
import InviteMembers from "../../components/community/inviteMembers";
import CommunityCard from "../../components/cards/communityCard";
import GroupRight from "../../components/initial/rights/groupRight";
// Assets
import EditIcon from "@mui/icons-material/Edit";
import { Check } from "@material-ui/icons";
import noGroupPic from "../../assets/images/no_group_pic.png";
// Libraries
import {
	Avatar,
	Button,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	useToast,
} from "@chakra-ui/react";

export default function FocusGroup() {
	// Default
	const { loggedIn } = useContext(Context);
	const toast = useToast();
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const { communityID } = useParams();
	// Specific
	const [newPic, setNewPic] = useState(null);
	const [newName, setNewName] = useState("");
	const [newBio, setNewBio] = useState("");

	const [editing, setEditing] = useState(false);
	const [communityInfo, setCommunityInfo] = useState({
		name: "",
		pic: "",
		communityID: "",
		bio: "",
	});
	const [parentInfo, setParentInfo] = useState({
		name: "",
		pic: "",
		communityID: "",
		bio: "",
	});
	const [adminInfo, setAdminInfo] = useState({
		isAdmin: false,
		isPublic: false,
		isIdVerificationRequired: false,
		isLocationBased: false,
	});
	const [joiningInfo, setJoiningInfo] = useState({
		isJoined: false,
		canJoin: false,
		requiresIdVerification: {
			isRequired: false,
			isFulfilled: false,
		},
		requiresEmailDomain: {
			isRequired: false,
			isFulfilled: false,
			domain: "",
		},
		requiresLocation: {
			isRequired: false,
			isFulfilled: false,
			locationInfo: {
				type: "",
				location: "",
			},
		},
	});
	const [postsInfo, setPostsInfo] = useState({
		polls: 0,
		proposals: 0,
		initiatives: 0,
	});
	// Requests
	const groupPage = async () => {
		setLoading(true);
		const result = await communityApi.data.groupPage(communityID);
		if (result.ok) {
			const data = result.data;
			setCommunityInfo(data.communityInfo);
			setParentInfo(data.parentInfo);
			setAdminInfo(data.adminInfo);
			setJoiningInfo(data.joiningInfo);
			setPostsInfo(data.postsInfo);
			setNewBio(data.communityInfo.bio);
			setNewName(data.communityInfo.name);
		}
		setLoading(false);
	};

	const joinCommunity = async () => {
		const result = await communityApi.members.acceptInvite(communityID);
		if (result.ok) {
			groupPage();
		}
	};
	const submitImg = async () => {
		if (newPic) {
			const formData = new FormData();
			formData.append("photo", newPic.image);
			await uploadPic(formData);
		}
	};
	const uploadPic = async (formData) => {
		const result = await communityApi.settings.uploadPic({
			communityID,
			formData,
		});
		if (result.ok) {
			toast({
				title: "Image Uploaded.",
				description: "It will take a few moments to update.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};
	const changeBio = async () => {
		const result = await communityApi.settings.uploadBio({
			purpose: newBio,
			communityID,
		});
		if (result.ok) {
		}
	};
	const changeName = async () => {
		const result = await communityApi.settings.uploadName({
			name: newName,
			communityID,
		});
		if (result.ok) {
			setEditing(false);
			toast({
				title: "Name Updated.",
				description: "",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};
	// Use Effects
	useEffect(() => {
		groupPage();
	}, [communityID]);

	return (
		<>
			<div id='app-left'>
				<LoadingWrapper loading={loading}>
					{loggedIn && (
						<Button
							fullWidth
							onClick={() => {
								if (joiningInfo.canJoin) {
									joinCommunity();
								}
							}}
							variant={joiningInfo.canJoin ? "contained" : "outlined"}
							disabled={!joiningInfo.canJoin}
						>
							{joiningInfo.canJoin ? "Join Community" : "Joined"}
						</Button>
					)}
				</LoadingWrapper>
			</div>
			<div id='app-right'>
				<GroupRight adminInfo={adminInfo} />
			</div>
			<div id='app-center'>
				<LoadingWrapper loading={loading}>
					{communityID !== "Global" && (
						<div id='group-settings' className='settings'>
							<OpenInApp
								docID={communityID}
								type={"group"}
								typeID={"communityID"}
							/>
							{adminInfo.isAdmin && !editing && (
								<Button
									onClick={() => setEditing(true)}
									variant='outlined'
									rightIcon={<EditIcon />}
								>
									Edit Community
								</Button>
							)}
							{!editing && (
								<>
									<div className='header'>
										<div className='avatar-container'>
											<Avatar
												className='avatar'
												name={communityInfo.name}
												src={communityInfo.pic ? communityInfo.pic : noGroupPic}
											/>
										</div>
										<div>
											<h2>{communityInfo.name}</h2>
											<p>{communityInfo.bio}</p>
											{joiningInfo.requiresEmailDomain.isRequired && (
												<p>
													Required Domain:{" "}
													<i>{joiningInfo.requiresEmailDomain.domain}</i>
												</p>
											)}
											{parentInfo.communityID.length > 0 && (
												<>
													<h3>Parent Community:</h3>
													<CommunityCard
														name={parentInfo.name}
														pic={parentInfo.pic}
														subGroups={[]}
														joined={false}
														isAdmin={false}
														groupID={parentInfo.communityID}
													/>
												</>
											)}
										</div>
									</div>
									<div className='section-card'>
										<div className='stat-container'>
											<h4>Community Stats</h4>
											<div className='stat'>
												<h3>{postsInfo.posts}</h3>
												<h5>Posts</h5>
											</div>
										</div>
										{adminInfo.isAdmin && (
											<div>
												<h4>Community Settings</h4>
												<AdminPreferences
													communityID={communityID}
													adminInfo={adminInfo}
												/>
												{!joiningInfo.requiresEmailDomain.isRequired && (
													<InviteMembers
														communityID={communityID}
														parentInfo={parentInfo}
													/>
												)}
											</div>
										)}
									</div>
								</>
							)}
							{editing && (
								<div className='editing'>
									<div className='button-group left'>
										<Button
											variant='outlined'
											onClick={() => {
												setNewName(communityInfo.name);
												setEditing(false);
											}}
										>
											Cancel
										</Button>
										<Button
											onPress={() => {
												if (newName !== communityInfo.name) {
													changeName();
												}
												if (newBio !== communityInfo.bio) {
													changeBio();
												}
												if (newPic) {
													submitImg();
												}
												setEditing(false);
												setCommunityInfo({
													...communityInfo,
													...{ name: newName, bio: newBio },
												});
											}}
											disabled={
												newName === communityInfo.name &&
												newBio === communityInfo.bio &&
												!newPic
											}
											variant='contained'
										>
											Confirm
										</Button>
									</div>
									<div className='header'>
										<UploadPicture
											uploadPic={uploadPic}
											currentPic={communityInfo.pic}
											newPic={newPic}
											setNewPic={setNewPic}
										/>
									</div>

									<div className='change-name text-field-wrapper'>
										<FormControl>
											<FormLabel>Name</FormLabel>
											<Input
												value={newName}
												onChange={(e) => {
													setNewName(e.target.value);
												}}
												placeholder='Enter a new name'
											/>
											<FormLabel>Bio</FormLabel>
											<Input
												value={newBio}
												onChange={(e) => {
													setNewBio(e.target.value);
												}}
												placeholder='About your community...'
											/>
										</FormControl>
									</div>
								</div>
							)}
						</div>
					)}
				</LoadingWrapper>
			</div>
		</>
	);
}
