// File: Full poll screen

// Default
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// API
import postsApi from "../../api/posts/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import CardHeader from "../../components/cards/cardComponents/cardHeader";
import CardOption from "../../components/cards/cardComponents/cardOption";
import CardFooter from "../../components/cards/cardComponents/cardFooter";
import CardBody from "../../components/cards/cardComponents/cardBody";
import Discussions from "../../components/discussions";
import OpenInApp from "../../components/post/openInApp";
import FocusLeft from "../../components/initial/lefts/focusLeft";
// Libraries
import { Button, IconButton, Tooltip } from "@chakra-ui/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../../components/post/sections/header";
import ParagraphText from "../../components/post/sections/paragraphText";
import List from "../../components/post/sections/list";
import Table from "../../components/post/sections/table";
import Quote from "../../components/post/sections/quote";
import PostMembers from "../../components/initial/rights/postMembers";

export default function FocusPost() {
	// Default
	const [loading, setLoading] = useState(true);
	// Nav
	const { postID } = useParams();
	const location = useLocation();
	// Specific
	const [decision, setDecision] = useState("");
	const [showDecision, setShowDecision] = useState(false);
	const [multiDecisions, setMultiDecisions] = useState([]);
	const [voteState, setVoteState] = useState(false);
	const [communityInfo, setCommunityInfo] = useState({
		name: "",
		pic: "",
		communityID: "",
	});
	const [footerInfo, setFooterInfo] = useState({ votes: 0, chats: 0 });
	const [postInfo, setPostInfo] = useState({ title: "", blocks: [] });
	const [userInfo, setUserInfo] = useState({
		name: "",
		pic: "",
		userID: "",
		username: "",
	});
	const [voterInfo, setVoterInfo] = useState({
		voteType: "",
		options: [],
		canVote: false,
	});
	const [dateInfo, setDateInfo] = useState({ fromNow: "" });
	// Requests
	const getPost = async () => {
		setLoading(true);
		const result = await postsApi.post.data.focus(postID);
		if (result.ok) {
			const data = result.data;
			setCommunityInfo(data.communityInfo);
			setFooterInfo(data.footerInfo);
			setPostInfo(data.postInfo);
			setUserInfo(data.userInfo);
			setVoterInfo(data.voterInfo);
			setDateInfo(data.dateInfo);
			setVoteState(data.voterInfo.voted);
			if (location.state && location.state.paramDecision) {
				if (data.voterInfo.voteType !== "Multi") {
					setDecision(location.state.paramDecision);
				} else {
					setMultiDecisions([{ option: location.state.paramDecision }]);
				}
			}
		}
		setLoading(false);
	};
	const vote = async () => {
		const result = await postsApi.vote.post({
			decision: voterInfo.voteType === "Multi" ? multiDecisions : decision,
			postID,
		});
		if (result.ok) {
			setVoteState(true);
		}
	};
	// Functions
	async function toggleDecision(receivedDecision) {
		if (voterInfo.voteType === "Multi") {
			if (multiDecisions.find((obj) => obj.option === receivedDecision)) {
				setMultiDecisions(
					multiDecisions.filter((d) => d.option !== receivedDecision)
				);
			} else {
				setMultiDecisions([
					...multiDecisions,
					...[{ option: receivedDecision }],
				]);
			}
		} else {
			if (decision === receivedDecision) {
				setDecision("");
			} else {
				setDecision(receivedDecision);
			}
		}
	}
	// UseEffects
	useEffect(() => {
		getPost();
	}, []);

	return (
		<>
			<div id="app-right">
				{!voteState && (
					<Button
						variant="contained"
						onClick={(e) => {
							e.stopPropagation();
							vote();
						}}
						disabled={
							voterInfo.options.length > 0
								? !(decision.length > 0 || multiDecisions.length > 0) ||
								  !voterInfo.canVote
								: !voterInfo.canVote
						}>
						Vote
					</Button>
				)}
				<PostMembers />
			</div>
			<div id="app-left">
				<FocusLeft
					profileID={userInfo.userID}
					isOrg={userInfo.isOrg}
					communityInfo={communityInfo}
				/>
			</div>
			<div id="app-center">
				<LoadingWrapper loading={loading}>
					<OpenInApp docID={postID} type={"post"} typeID={"postID"} />
					<div className="card">
						<CardHeader
							name={userInfo.name}
							username={userInfo.username}
							pic={userInfo.pic}
							date={dateInfo.fromNow}
							cardUserID={userInfo.userID}
							isOrg={userInfo.isOrg}
							voted={voterInfo.voted}
							type={"post"}
							communityInfo={communityInfo}
						/>
						<CardBody
							title={postInfo.title}
							body={postInfo.body}
							isTruncated={postInfo.isTruncated}
						/>
						{!voteState && voterInfo.options.length > 0 && (
							<p className="secondary">
								{voterInfo.voteType === "Default"
									? "Select one option"
									: voterInfo.voteType === "Multi"
									? "You may select multiple options"
									: "You may join this initiative"}
							</p>
						)}
						<div className="card-body">
							{voteState && voterInfo.options.length > 0 && (
								<div className="toggle-decision">
									<Tooltip
										label={
											!showDecision
												? "Show Your Decision"
												: "Hide Your Decision"
										}>
										<IconButton
											onClick={(e) => {
												e.stopPropagation();
												setShowDecision(!showDecision);
											}}
											variant="text"
											size="sm"
											icon={
												!showDecision ? (
													<Visibility fontSize="small" className="secondary" />
												) : (
													<VisibilityOff
														fontSize="small"
														className="secondary"
													/>
												)
											}
										/>
									</Tooltip>
								</div>
							)}

							{voterInfo.options.map(function (d, idx) {
								return (
									<li key={idx} className={`option-wrapper`}>
										<CardOption
											optionText={d.option}
											votes={d.votes}
											voted={voterInfo.voted}
											decision={
												voterInfo.voted
													? voterInfo.yourVote
														? voterInfo.yourVote.decision
														: null
													: decision
											}
											totalVotes={footerInfo.votes}
											onClick={(decision) => {
												toggleDecision(decision);
											}}
											disabled={!voterInfo.canVote}
											optionsType={voterInfo.voteType}
											multiDecisions={
												voterInfo.voted
													? voterInfo.yourVote
														? voterInfo.yourVote.decision
														: []
													: multiDecisions
											}
											showDecision={showDecision}
										/>
									</li>
								);
							})}
						</div>
						<CardFooter
							totalVotes={footerInfo.votes}
							chats={footerInfo.chats}
							postID={postInfo.postID}
							type={"post"}
						/>
						{postInfo.blocks.map(function (d, idx) {
							return (
								<div className="block">
									{d.type === "header" && (
										<Header level={d.data.level} text={d.data.text} />
									)}
									{d.type === "paragraph" && (
										<ParagraphText text={d.data.text} />
									)}
									{d.type === "list" && (
										<List
											items={d.data.items}
											style={d.data.style}
											level={1}
											parentIndex={0}
										/>
									)}
									{d.type === "delimiter" && (
										<div className="delimiter">
											<h1>. . .</h1>
										</div>
									)}
									{d.type === "table" && (
										<Table
											content={d.data.content}
											withHeadings={d.data.withHeadings}
										/>
									)}

									{d.type === "quote" && (
										<Quote text={d.data.text} caption={d.data.caption} />
									)}
								</div>
							);
						})}
					</div>
					<Discussions
						type={"post"}
						identifier={postID}
						canDiscuss={voterInfo.canDiscuss}
					/>
				</LoadingWrapper>
			</div>
		</>
	);
}
