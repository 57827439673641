// File: Reusable preference
// Default
import React from "react";
// Nav
import { useNavigate } from "react-router-dom";
// Assets
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// Libraries
import { Switch } from "@chakra-ui/react";

export default function PreferenceButton({
	type,
	title,
	switchBool,
	setSwitchBool,
	switchOnMsg,
	switchOffMsg,
	toggleSwitch,
	navigateDestination,
	target,
	onClick,
}) {
	// Nav
	const navigate = useNavigate();
	if (type === "switch") {
		return (
			<button
				className='preference'
				onClick={() => {
					if (switchBool) {
						setSwitchBool(!switchBool);
					}
				}}
			>
				<div>
					<p>{title}</p>
					{switchBool && <p className='small secondary'>{switchOnMsg}</p>}
					{!switchBool && <p className='small secondary'>{switchOffMsg}</p>}
				</div>
				<Switch
					onChange={() => {
						setSwitchBool(!switchBool);
						toggleSwitch();
					}}
					color='primary'
					isChecked={switchBool}
				/>
			</button>
		);
	}
	if (type === "navigate") {
		return (
			<button
				className='preference'
				onClick={() => {
					navigate(navigateDestination);
				}}
			>
				<p>{title}</p>
				<ChevronRightIcon />
			</button>
		);
	}
	if (type === "function") {
		return (
			<button className='preference' onClick={onClick}>
				<p>{title}</p>
			</button>
		);
	}
	if (type === "link") {
		return (
			<a className='preference' href={navigateDestination} target={target}>
				<p>{title}</p>
				<OpenInNewIcon />
			</a>
		);
	}
}
