// File: Full discussion screen
// Default
import React, { useEffect, useState } from "react";
// Nav
import { useParams } from "react-router-dom";
// API
import postsApi from "../../api/posts/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import CardHeader from "../../components/cards/cardComponents/cardHeader";
import CardBody from "../../components/cards/cardComponents/cardBody";
import Discussions from "../../components/discussions";
import OpenInApp from "../../components/post/openInApp";
import CardFooter from "../../components/cards/cardComponents/cardFooter";
import FocusLeft from "../../components/initial/lefts/focusLeft";

export default function FocusDiscussion() {
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const { postID } = useParams();
	// Specific
	const [footerInfo, setFooterInfo] = useState({ votes: 0, chats: 0 });
	const [postInfo, setPostInfo] = useState({ title: "" });
	const [userInfo, setUserInfo] = useState({
		name: "",
		pic: "",
		userID: "",
		username: "",
	});
	const [voterInfo, setVoterInfo] = useState({
		optionsType: "",
		options: [],
		canVote: false,
	});
	const [dateInfo, setDateInfo] = useState({ fromNow: "" });
	// Requests
	const getDiscussion = async () => {
		setLoading(true);
		const result = await postsApi.discussion.data.focus(postID);
		if (result.ok) {
			const data = result.data;
			setFooterInfo(data.footerInfo);
			setPostInfo(data.postInfo);
			setUserInfo(data.userInfo);
			setVoterInfo(data.voterInfo);
			setDateInfo(data.dateInfo);
		}
		setLoading(false);
	};
	// Use Effects
	useEffect(() => {
		getDiscussion();
	}, [postID]);
	return (
		<>
			<div id='app-left'>
				<FocusLeft profileID={userInfo.userID} isOrg={userInfo.isOrg} />
			</div>
			<div id='app-right'>
				<Discussions
					type={"discussion"}
					identifier={postID}
					canDiscuss={voterInfo.canDiscuss}
				/>
			</div>
			<div id='app-center'>
				<LoadingWrapper loading={loading}>
					<OpenInApp docID={postID} type={"announcement"} typeID={"postID"} />
					<div className='card'>
						<CardHeader
							name={userInfo.name}
							username={userInfo.username}
							pic={userInfo.pic}
							date={dateInfo.fromNow}
							cardUserID={userInfo.userID}
							isOrg={userInfo.isOrg}
							voted={voterInfo.voted}
							type={"discussion"}
						/>
						<CardBody
							title={postInfo.title}
							body={postInfo.body}
							isTruncated={postInfo.isTruncated}
						/>
						<CardFooter
							totalVotes={footerInfo.votes}
							chats={footerInfo.chats}
							postID={postInfo.postID}
							type={"discussion"}
						/>
					</div>
				</LoadingWrapper>
			</div>
		</>
	);
}
