// File: Choosing between multi and default
// Default
import React, { useState } from "react";
// Assets
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
// Libraries
import { Radio } from "@chakra-ui/react";

export default function SelectPollType({ optionsType, setOptionsType }) {
	// Specific
	const defaultTitle = "Single Choice Post";
	const multiTitle = "Multiple Choice Post";

	const [expanded, setExpanded] = useState(false);
	const [typeTitle, setTypeTitle] = useState(
		optionsType === "Default" ? defaultTitle : multiTitle
	);
	let pollTypesArr = [
		{
			type: "Default",
			label: "Single Choice",
			description: "Voters can only select on option",
			handleClick: () => {
				setExpanded(false);
				setOptionsType("Default");
				setTypeTitle(defaultTitle);
			},
		},
		{
			type: "Multi",
			label: "Multiple Choice",
			description: "Members can vote for multiple options simultaneously",
			handleClick: () => {
				setExpanded(false);
				setOptionsType("Multi");
				setTypeTitle(multiTitle);
			},
		},
	];

	return (
		<div className='select-poll-type'>
			<button onClick={() => setExpanded(!expanded)} class='header'>
				{optionsType !== "" ? (
					<p>Creating a {typeTitle}</p>
				) : (
					<p>Please Select A Poll Type</p>
				)}
				{!expanded ? (
					<ArrowDropDown fontSize='large' />
				) : (
					<ArrowDropUp fontSize='large' />
				)}
			</button>
			{expanded && (
				<div>
					{pollTypesArr.map((item, idx) => {
						return (
							<button className='poll-type' onClick={item.handleClick}>
								<div className='content'>
									<Radio
										touchRipple={false}
										isChecked={optionsType === item.type}
										onChange={item.handleClick}
									/>
									<div className='text'>
										<h5>{item.label}</h5>
										<p>{item.description}</p>
									</div>
								</div>
							</button>
						);
					})}
				</div>
			)}
		</div>
	);
}
