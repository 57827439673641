// File: Opens link in app
// Default
import React from "react";

export default function OpenInApp({ type, docID, typeID }) {
	return (
		<div className='visible-mobile open-in-app'>
			<a
				href={`quae:///--/${type}?${typeID}=${docID}`}
				target='_blank'
				rel='noreferrer'
			>
				Open In App
			</a>
		</div>
	);
}
