// File: Full screen community right margin, shows admins and members of community
// Default
import React, { useEffect, useState } from "react";
// Nav
import { useParams } from "react-router-dom";
// API
import communityApi from "../../../api/community/main";
// Helper
import LoadingWrapper from "../../../helper/loadingWrapper";
// Components
import MemberCard from "../../cards/memberCard";

export default function GroupRight({ adminInfo }) {
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const { communityID } = useParams();
	// Specific
	const [admins, setAdmins] = useState([]);
	const [members, setMembers] = useState([]);
	// Requests
	const getMembers = async () => {
		setLoading(true);
		const result = await communityApi.members.getMembers(communityID);
		if (result.ok) {
			setMembers(result.data);
		}
		setLoading(false);
	};
	const getAdmins = async () => {
		const result = await communityApi.members.getAdmins(communityID);
		if (result.ok) {
			setAdmins(result.data);
		}
	};
	// Use Effects
	useEffect(() => {
		getMembers();
		getAdmins();
	}, []);
	return (
		<div className='community-right members section-card'>
			<LoadingWrapper loading={loading}>
				<h3>Admins</h3>

				{admins.map(function (item, index) {
					return (
						<MemberCard
							name={item.name}
							pic={item.pic}
							username={item.username}
							cardUserID={item.userID}
							isAdmin={adminInfo.isAdmin}
							userIsAdmin
							communityID={communityID}
						/>
					);
				})}
				<h3>Members</h3>
				{members.map(function (item, index) {
					return (
						<MemberCard
							name={item.name}
							pic={item.pic}
							username={item.username}
							cardUserID={item.userID}
							isAdmin={adminInfo.isAdmin}
							communityID={communityID}
						/>
					);
				})}
			</LoadingWrapper>
		</div>
	);
}
