// File: Activate your account
// Default
import React from "react";
// Libraries
import { Button } from "@chakra-ui/react";

export default function ActivateAccount({ activationRef }) {
	return (
		<div id='activate-account' ref={activationRef} className='section-card'>
			<h3>Verify Your Account</h3>
			<Button variant={"contained"}>Verify</Button>
		</div>
	);
}
