// File: Reusable Tabs
// Default
import React from "react";
// Theming
import "../styles/components/_tabButtons.scss";

export default function Tabs({ tabs, large }) {
	return (
		<div className='custom-tabs'>
			{tabs.map(function (item) {
				return (
					<button
						onClick={item.onClick}
						className={`tab-btn ${item.focused ? "focused" : ""} ${
							large ? "large" : ""
						}`}
					>
						{large ? <h3>{item.text}</h3> : <p>{item.text}</p>}
					</button>
				);
			})}
		</div>
	);
}
