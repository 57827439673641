import client from "../client";
const endpoint = "/posts/vote";

const post = ({ decision, postID }) =>
	client.post(`${endpoint}/post`, { decision, postID });

const vote = {
	post,
};
export default vote;
