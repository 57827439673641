// File: Profile Screen
// Default
import React, { useState, useEffect } from "react";
// Nav
import { useParams } from "react-router-dom";
// API
import postsApi from "../../api/posts/main.js";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper.js";
// Components
import FeedCard from "../../components/cards/feedCard.js";
import ProfileLeft from "../../components/initial/lefts/profileLeft.js";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ProfileScreen() {
	// Loading
	const [loading, setLoading] = useState(false);
	const [refreshing, setRefreshing] = useState(false);
	const [skip, setSkip] = useState(0);
	const [bottomLoading, setBottomLoading] = useState(false);
	// Nav
	const { profileID, isOrg } = useParams();
	// Specific
	const [posts, setPosts] = useState([]);
	const [finished, setFinished] = useState(false);
	// Requests
	const profileFeed = async (fromBot) => {
		if (fromBot ? !finished : true) {
			async function getSkip() {
				if (fromBot === true) {
					let tempSkip = skip;
					setSkip(tempSkip + 1);
					setBottomLoading(true);
					return tempSkip + 1;
				} else {
					setPosts([]);
					setSkip(0);
					setLoading(true);
					return 0;
				}
			}
			if (fromBot ? !loading && !bottomLoading : !loading) {
				await getSkip().then(async (data) => {
					const result = await postsApi.newFeeds.profileAll({
						userID: profileID,
						skip: data,
						isOrg,
					});
					if (result.ok) {
						if (fromBot) {
							setPosts([...posts, ...result.data]);
							if (result.data.length === 0) {
								setFinished(true);
							} else {
								setFinished(false);
							}
						} else {
							setPosts(result.data);
						}
					}
					setLoading(false);
					setBottomLoading(false);
				});
			}
		}
	};
	useEffect(() => {
		profileFeed();
	}, [profileID]);
	return (
		<>
			<div id='app-left'>
				<ProfileLeft />
			</div>
			<div id='app-center' className='profile-screen'>
				<LoadingWrapper loading={loading}>
					<InfiniteScroll
						dataLength={posts.length}
						next={() => {
							profileFeed(true);
						}}
						hasMore={!finished}
						loader={
							<LoadingWrapper loading={true}>
								<></>
							</LoadingWrapper>
						}
						height={"100vh"}
						className='home-feed'
						endMessage={
							<p style={{ textAlign: "center" }}>
								<b>Congrats! You've reached the end of the feed</b>
							</p>
						}
					>
						{posts.map(function (item, index) {
							return <FeedCard item={item} />;
						})}
					</InfiniteScroll>
				</LoadingWrapper>
			</div>
		</>
	);
}
