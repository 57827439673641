// File: Button to create Post
// Default
import React, { useContext } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// Assets
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
// Theming
import "../../styles/components/_beHeard.scss";
// Libraries
import { Button } from "@chakra-ui/react";

export default function BeHeardButton() {
	// Default
	const { loggedIn } = useContext(Context);
	// Nav
	const navigate = useNavigate();

	return (
		<Button
			leftIcon={<RecordVoiceOverIcon className='icon-white' />}
			isFullWidth
			colorScheme={"primary"}
			variant='contained'
			disabled={!loggedIn}
			onClick={() => {
				navigate(`/post/create/${null}`);
			}}
		>
			Create Post
		</Button>
	);
}
