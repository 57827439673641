// File:
// Default
import React, { useEffect, useRef, useState } from "react";
// Nav
import { useNavigate, useParams } from "react-router-dom";
// API
import postsApi from "../../api/posts/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import CreateLeft from "../../components/initial/lefts/createLeft";
// Assets
import SaveIcon from "@material-ui/icons/Save";
import UploadIcon from "@material-ui/icons/RecordVoiceOver";
// Theming
import "../../styles/components/editorJs.scss";
// Libraries
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Embed from "@editorjs/embed";
import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import Table from "@editorjs/table";
import InlineCode from "@editorjs/inline-code";
import Marker from "@editorjs/marker";
import Underline from "@editorjs/underline";
import NestedList from "@editorjs/nested-list";
import TextVariantTune from "@editorjs/text-variant-tune";
import Paragraph from "editorjs-paragraph-with-alignment";
import {
	Button,
	FormControl,
	Input,
	FormLabel,
	useToast,
} from "@chakra-ui/react";
import SelectPollType from "../../components/post/poll/selectPollType";
import SetPollOptions from "../../components/post/poll/setPollOptions";
// Vars
const EDITOR_HOLDER_ID = "editorjs";

export default function CreatePost() {
	// Loading
	const [loading, setLoading] = useState(true);
	const toast = useToast();
	// Nav
	const { postID } = useParams();
	const navigate = useNavigate();

	// Specific
	const [title, setTitle] = useState("");
	const [body, setBody] = useState("");
	const [posterID, setPosterID] = useState("");
	const [options, setOptions] = useState([]);
	const [voteType, setVoteType] = useState("");
	const [tags, setTags] = useState([]);
	const [isOrg, setIsOrg] = useState(false);
	const [addingOptions, setAddingOptions] = useState(false);

	const [communityID, setCommunityID] = useState("Global");
	const [communityInfo, setCommunityInfo] = useState({
		name: "Global",
		pic: "",
		communityID: "Global",
	});
	const ejInstance = useRef();
	const [editorData, setEditorData] = useState({});
	// Requests
	const getPost = async () => {
		setLoading(true);
		const result = await postsApi.post.create.getDraft(postID);
		if (result.ok) {
			const data = result.data;
			setTitle(data.postInfo.title);
			setBody(data.postInfo.body);
			setVoteType(data.voterInfo.voteType);
			if (data.voterInfo.optionsArray.length > 0) {
				setAddingOptions(true);
			}
			setOptions(data.voterInfo.optionsArray);
			setTags(data.postInfo.tags);
			setCommunityInfo(data.communityInfo);
			setCommunityID(data.communityInfo.communityID);
			setPosterID(data.userInfo.userID);
			setIsOrg(data.userInfo.isOrg);
			setEditorData({
				time: new Date().getTime(),
				blocks: data.postInfo.blocks,
			});
			setLoading(false);
			return {
				time: new Date().getTime(),
				blocks: data.postInfo.blocks,
			};
		}
	};

	const saveDraft = async (publishing) => {
		const result = await postsApi.post.create.saveDraft({
			title,
			body,
			blocks: editorData.blocks,
			options,
			userID: posterID,
			isOrg,
			voteType,
			postID,
			communityID,
			tags,
		});
		if (result.ok) {
			if (postID === ("null" || null)) {
				if (publishing) {
					publish(result.data.postID);
				} else {
					navigate(`/post/create/${result.data.postID}`);
				}
			} else {
				if (publishing) {
					publish(postID);
				} else {
				}
			}
			toast({
				title: "",
				description: "Saved",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};

	const publish = async (postID) => {
		const result = await postsApi.post.create.publish(postID);
		if (result.ok) {
			toast({
				title: "",
				description: "Published",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
			navigate("/");
		}
	};
	// Functions
	function startIt() {
		getPost().then((data) => {
			initEditor(data);
		});
	}
	const initEditor = async (data) => {
		const editor = new EditorJS({
			holder: EDITOR_HOLDER_ID,
			logLevel: "ERROR",
			data,
			onReady: () => {
				ejInstance.current = editor;
			},
			onChange: async (o) => {
				let content = await o.saver.save();
				setEditorData(content);
			},
			tools: {
				header: { class: Header, inlineToolbar: true },
				embed: {
					class: Embed,
					inlineToolbar: true,
				},
				quote: {
					class: Quote,
					inlineToolbar: true,
					config: {
						quotePlaceholder: "Enter a quote",
						captionPlaceholder: "Quote's author",
					},
				},
				list: {
					class: NestedList,
					inlineToolbar: true,
				},
				delimiter: Delimiter,
				table: Table,
				inlineCode: {
					class: InlineCode,
				},
				Marker: {
					class: Marker,
					shortcut: "CMD+H",
				},
				underline: { class: Underline, shortcut: "CMD+U" },
				textVariant: TextVariantTune,
				paragraph: {
					class: Paragraph,
					inlineToolbar: true,
				},
				// ballot: { class: Ballot, inlineToolbar: true },
			},
		});
	};
	// Use Effects
	useEffect(() => {
		if (postID !== (null || "null")) {
			startIt();
		} else {
			initEditor({
				time: new Date().getTime(),
				blocks: [],
			});
			setLoading(false);
		}
	}, [postID]);

	return (
		<>
			<div id='app-left'>
				<CreateLeft
					communityID={communityID}
					setCommunityID={setCommunityID}
					communityInfo={communityInfo}
					setCommunityInfo={setCommunityInfo}
				/>
				<Button
					variant='text'
					onClick={() => {
						navigate("/post");
					}}
				>
					Drafts
				</Button>
			</div>
			<div id='app-right'>
				<>
					<Button
						onClick={() => {
							saveDraft();
						}}
						variant='outlined'
						startIcon={<SaveIcon className='text' />}
					>
						Save
					</Button>
					<Button
						variant='contained'
						onClick={() => {
							saveDraft(true);
						}}
						startIcon={<UploadIcon style={{ color: "white" }} />}
						disabled={title.length === 0}
					>
						Publish
					</Button>
				</>
			</div>
			<div id='app-center'>
				<LoadingWrapper loading={loading}>
					<div className='proposal-header'>
						<div className='text-field-wrapper'>
							<FormControl>
								<FormLabel>Title</FormLabel>
								<Input
									label='Title'
									placeholder='What Are You Writing About?'
									onChange={(e) => {
										setTitle(e.target.value);
									}}
									value={title}
									isFullWidth
								/>
								<FormLabel>Body</FormLabel>
								<Input
									label='Body'
									placeholder='Any important details?'
									onChange={(e) => {
										setBody(e.target.value);
									}}
									value={body}
									isFullWidth
								/>

								<div className='button-group center mt'></div>
							</FormControl>
						</div>
						<Button
							variant='text'
							onClick={() => {
								setAddingOptions(!addingOptions);
								if (addingOptions) {
									setOptions([]);
								}
							}}
						>
							{!addingOptions ? "Add Options" : "Cancel"}
						</Button>
						{addingOptions && (
							<>
								<SetPollOptions
									optionsString={options}
									setOptionsString={setOptions}
									optionsType={voteType}
								/>
								<SelectPollType
									optionsType={voteType}
									setOptionsType={setVoteType}
									setOptionsString={setOptions}
								/>
							</>
						)}
						<FormLabel>
							Proposal <p className='secondary'>(optional)</p>
						</FormLabel>

						<div
							className='write-proposal'
							id={EDITOR_HOLDER_ID}
							data-placeholder='Start Writing...'
						></div>
					</div>
				</LoadingWrapper>
			</div>
		</>
	);
}
