// File: Settings screen
// Default
import React, { useContext } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// Components
import PreferenceButton from "../../components/community/preferenceButton";
// Libraries
import { Button, useColorMode } from "@chakra-ui/react";

export default function Settings() {
	// Default
	const { toggleColorMode } = useColorMode();
	const { setLoggedIn, setUserID, loggedIn } = useContext(Context);
	// Nav
	const navigate = useNavigate();
	// Specific
	const settingsButtons = [
		{
			canAccess: loggedIn,
			title: "Profile Settings",
			type: "navigate",
			destination: "/settings/profile",
		},
		{
			canAccess: true,
			title: "EULA",
			type: "link",
			destination: "https://quae.app/pages/eula",
			target: "_blank",
		},
		{
			canAccess: true,
			title: "Privacy Policy",
			type: "link",
			destination: "https://quae.app/pages/privacy_agreement/",
			target: "_blank",
		},
		{
			canAccess: loggedIn,
			title: "Sign Out",
			type: "function",
			onclick: () => signOut(),
		},
	];
	// Functions
	const signOut = async () => {
		await localStorage.removeItem("token");
		setLoggedIn(false);
		setUserID("");
		navigate("/");
	};

	return (
		<>
			<div id='app-center'>
				<h2>Settings</h2>
				<Button variant='contained' onClick={toggleColorMode}>
					Toggle Theme
				</Button>
				<div className='preferences-container section-card'>
					{settingsButtons.map((item) => {
						if (item.canAccess) {
							return (
								<PreferenceButton
									type={item.type}
									title={item.title}
									navigateDestination={item.destination}
									target={item.target}
									switchBool={item.switchBool}
									setSwitchBool={item.setSwitchBool}
									toggleSwitch={item.toggleSwitch}
									onClick={item.onclick}
								/>
							);
						} else return <></>;
					})}
				</div>
			</div>
		</>
	);
}
