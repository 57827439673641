import { extendTheme } from "@chakra-ui/react";

const config = {
	initialColorMode: "light",
	useSystemColorMode: false,
};
const colors = {
	dark: {
		primary: "#8A60FF",
		text: "#FCFCFC",
		secondaryText: "#cccccc",
		background: "#151515",
		foreground: "#222222",
		card: "#3B3B3B",
		danger: "#E74F4F",
	},
	light: {
		primary: "#8F67FF",
		text: "#000000",
		secondaryText: "#666666",
		background: "#FFFFFF",
		foreground: "#F0F0F0",
		card: "#FFFFFF",
		danger: "#F66363",
	},
	blue: {
		50: "#8F67FF",
		100: "#8F67FF",
		200: "#8F67FF",
		300: "#8F67FF",
		400: "#8F67FF",
		500: "#8F67FF",
		600: "#8F67FF",
		700: "#8F67FF",
		800: "#8F67FF",
		900: "#8F67FF",
	},
};
const components = {
	Button: {
		baseStyle: {
			margin: 0.5,
			_hover: { opacity: "75%" },
			_focus: {
				boxShadow: "none",
			},
		},
		sizes: {
			xl: {
				h: "56px",
				fontSize: "lg",
				px: "32px",
			},
		},
		variants: {
			contained: (props) => ({
				color: "white",
				...(props.colorMode === "dark"
					? { backgroundColor: colors.dark.primary }
					: { backgroundColor: colors.light.primary }),
			}),
			danger: (props) => ({
				color: "white",
				...(props.colorMode === "dark"
					? { backgroundColor: colors.dark.danger }
					: { backgroundColor: colors.light.danger }),
			}),
			outlined: (props) => ({
				borderWidth: 1,
				borderStyle: "solid",
				...(props.colorMode === "dark"
					? {
							borderColor: colors.dark.secondaryText,
							color: colors.dark.text,
							_hover: { backgroundColor: colors.dark.foreground },
					  }
					: {
							borderColor: colors.light.secondaryText,
							color: colors.light.text,
							_hover: { backgroundColor: colors.light.foreground },
					  }),
			}),
			text: (props) => ({
				...(props.colorMode === "dark"
					? { _hover: { backgroundColor: colors.dark.foreground } }
					: { _hover: { backgroundColor: colors.light.foreground } }),
			}),
			filled: (props) => ({
				...(props.colorMode === "dark"
					? { backgroundColor: colors.dark.foreground }
					: { backgroundColor: colors.light.foreground }),
			}),
		},
	},

	Tooltip: {
		baseStyle: (props) => ({
			borderRadius: 5,
			...(props.colorMode === "dark"
				? { backgroundColor: colors.dark.card, color: colors.dark.text }
				: {
						backgroundColor: colors.light.card,
						color: colors.light.text,
				  }),
		}),
	},
	Alert: {
		backgroundColor: "red",
		variants: {
			regular: (props) => ({
				// only applies to `subtle` variant
			}),
		},
	},
	Input: {
		baseStyle: {},
		variants: {
			filled: (props) => ({
				...(props.colorMode === "dark"
					? { backgroundColor: colors.dark.card }
					: { backgroundColor: colors.light.card }),
			}),
		},
	},
	Popover: {
		baseStyle: {
			backgroundColor: "red",
			_focus: {
				boxShadow: "none",
			},
		},
		// backgroundColor: "red",
		// _focus: {
		// 	boxShadow: "none",
		// },
	},
};
const chakraTheme = extendTheme({ components, colors, config });
export default chakraTheme;
