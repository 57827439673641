// File: Universal card for feeds
// Default
import React, { useState } from "react";
// Nav
import { useNavigate } from "react-router-dom";
// Components
import CardHeader from "./cardComponents/cardHeader";
import CardBody from "./cardComponents/cardBody";
import CardFooter from "./cardComponents/cardFooter";
import CardOption from "./cardComponents/cardOption";
import { Button, IconButton, Tooltip } from "@chakra-ui/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function FeedCard({ item }) {
	// Nav
	const navigate = useNavigate();
	const [showDecision, setShowDecision] = useState(false);
	return (
		<div className="card-wrapper">
			<button
				className="card"
				onClick={() => {
					navigate(`/focus/${item.type}/${item.postInfo.postID}`);
				}}>
				<CardHeader
					name={item.userInfo.name}
					username={item.userInfo.username}
					pic={item.userInfo.pic}
					date={item.dateInfo.fromNow}
					cardUserID={item.userInfo.userID}
					isOrg={item.userInfo.isOrg}
					voted={item.voterInfo.voted}
					type={item.type}
					communityInfo={item.communityInfo}
				/>
				<CardBody
					title={item.postInfo.title}
					body={item.postInfo.body}
					isTruncated={item.postInfo.isTruncated}
				/>
				<div className="card-body">
					{item.voterInfo.voted && item.voterInfo.options.length > 1 && (
						<div className="toggle-decision">
							<Tooltip
								label={
									!showDecision ? "Show Your Decision" : "Hide Your Decision"
								}>
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										setShowDecision(!showDecision);
									}}
									variant="text"
									size="sm"
									icon={
										!showDecision ? (
											<Visibility fontSize="small" className="secondary" />
										) : (
											<VisibilityOff fontSize="small" className="secondary" />
										)
									}
								/>
							</Tooltip>
						</div>
					)}

					{item.voterInfo.options.map(function (d, idx) {
						return (
							<li key={idx}>
								<CardOption
									optionText={d.option}
									votes={d.votes}
									voted={item.voterInfo.voted}
									decision={
										item.voterInfo.voted
											? item.voterInfo.yourVote
												? item.voterInfo.yourVote.decision
												: null
											: ""
									}
									totalVotes={item.footerInfo.votes}
									disabled={!item.voterInfo.canVote}
									optionsType={item.voterInfo.voteType}
									multiDecisions={
										item.voterInfo.voted
											? item.voterInfo.yourVote
												? item.voterInfo.yourVote.decision
												: []
											: []
									}
									showDecision={showDecision}
									onClick={(decision) => {
										navigate(`/focus/${item.type}/${item.postInfo.postID}`, {
											state: { paramDecision: decision },
										});
									}}
								/>
							</li>
						);
					})}
				</div>
				<CardFooter
					totalVotes={item.footerInfo.votes}
					chats={item.footerInfo.chats}
					postID={item.postInfo.postID}
					type={item.type}
					voted={item.voterInfo.voted}
				/>
			</button>
		</div>
	);
}
