// File: Create a community
// Default
import React, { useContext, useState } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// API
import communityApi from "../../api/community/main";
// Components
import Tabs from "../../components/tabs";
// Libraries
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Switch,
} from "@chakra-ui/react";

export default function CreateCommunity() {
	// Default
	const { loggedIn } = useContext(Context);
	// Loading
	const [loading, setLoading] = useState(false);
	// Nav
	const navigate = useNavigate();
	// Specific
	const tabs = [
		{
			text: "Join",
			onClick: () => navigate("/community/join"),
			focused: false,
		},
		{
			text: "Create",
			onClick: () => navigate("/community/create"),
			focused: true,
		},
	];
	const [name, setName] = useState("");
	const [purpose, setPurpose] = useState("");
	const [parent, setParent] = useState("Global");
	const [visible, setVisible] = useState(false);
	// Requests
	const createGroup = async () => {
		setLoading(true);
		const result = await communityApi.create.createCommunity({
			name,
			purpose,
			parent,
			visible,
		});
		if (result.ok) {
			navigate("/focus/community/" + result.data);
		}
		setLoading(false);
	};

	return (
		<div id='app-center'>
			<h2>Create a New Community</h2>
			<Tabs tabs={tabs} />
			<div className='section-card mt-L'>
				<FormControl>
					<FormLabel>Name</FormLabel>
					<Input
						onChange={(e) => {
							setName(e.target.value);
						}}
						value={name}
						placeholder='What is your community called?'
					/>
					<FormLabel>Bio</FormLabel>

					<Input
						onChange={(e) => {
							setPurpose(e.target.value);
						}}
						value={purpose}
						placeholder='What is the purpose for your community?'
						multiple
					/>
					<FormLabel>Public</FormLabel>
					<Switch
						onChange={() => {
							setVisible(!visible);
						}}
						isChecked={visible}
					/>
				</FormControl>

				<Button
					className='mt'
					isFullWidth
					onClick={() => {
						createGroup();
					}}
					disabled={name.length === 0 || !loggedIn}
					variant='contained'
					isLoading={loading}
				>
					Create Community
				</Button>
			</div>
		</div>
	);
}
