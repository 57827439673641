import client from "../client";
const endpoint = "/community/create";

const createCommunity = ({ name, purpose, visible, parent }) =>
	client.post(`${endpoint}/createCommunity`, {
		name,
		purpose,
		visible,
		parent,
	});

const create = {
	createCommunity,
};
export default create;
