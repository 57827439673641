// File: Table for proposal
// Default
import React from "react";

export default function Table({ content, withHeadings }) {
	return (
		<div className='table scroll-auto'>
			{withHeadings && (
				<table>
					<thead>
						<tr>
							{content[0].map((item, index) => {
								return (
									<th>
										<p>{item}</p>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{content.slice(1, content.length).map((items, index) => {
							return (
								<tr>
									{items.map((subItems, sIndex) => {
										return (
											<td>
												<p>{subItems}</p>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
			{!withHeadings && (
				<table>
					<tbody>
						{content.map((items, index) => {
							return (
								<tr>
									{items.map((subItems, sIndex) => {
										return (
											<td>
												<p>{subItems}</p>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</div>
	);
}
