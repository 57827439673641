// File: icon for footer and drawer container
// default
import React, { useRef } from "react";
//libraries
import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import MenuIcon from "@mui/icons-material/Menu";
//components
import HomeLeft from "../initial/lefts/homeLeft";
export default function MobileDrawer() {
	// Specific
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = useRef();
	//useEffects

	return (
		<>
			<button className='icon-btn' ref={btnRef} onClick={onOpen}>
				<MenuIcon />
			</button>
			<Drawer
				isOpen={isOpen}
				placement='left'
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader></DrawerHeader>
					<DrawerBody>
						<HomeLeft mobile />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
