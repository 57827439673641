import auth from "./auth";
import data from "./data";
import profile from "./profile";
import support from "./support";

const usersApi = {
	auth,
	data,
	profile,
	support,
};
export default usersApi;
