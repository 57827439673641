// File:
// Default
import React, { useState } from "react";
// Nav
import { Route, Routes } from "react-router-dom";
// Components
import MobileFooter from "../components/initial/mobileFooter.js";
import Header from "../components/initial/header.js";
import HomeFeed from "../screens/home/homeFeed.js";
import BeHeard from "../screens/post/beHeard.js";

import FocusGroup from "../screens/focus/focusGroup.js";
import Settings from "../screens/settings/settings.js";
import ProfileSettings from "../screens/settings/profileSettings.js";
import ProfileScreen from "../screens/profile/profile.js";
import Login from "../screens/auth/login.js";
import JoinCommunity from "../screens/community/joinCommunity";
import CreateCommunity from "../screens/community/createCommunity";
import SignUp from "../screens/auth/signup";
import VerifyPhone from "../screens/auth/verifyPhone";
import ForgotPassword from "../screens/auth/forgotPassword";
import ForgotPasswordVerify from "../screens/auth/forgotPasswordVerify.js";
import Inbox from "../screens/profile/inbox.js";
import FocusDiscussion from "../screens/focus/focusDiscussion.js";
import AccountActivation from "../screens/payments/accountActivation.js";
import SingleFeed from "../screens/home/singleFeed.js";
import EmailVerification from "../screens/auth/emailVerification.js";
import SearchFeed from "../screens/home/searchFeed.js";
import CreatePost from "../screens/post/createPost.js";
import FocusPost from "../screens/focus/focusPost.js";
import FocusReport from "../screens/focus/focusReport.js";

export default function InitialRouter() {
	const [expanded, setExpanded] = useState(true);

	return (
		<div id={"app-container"}>
			<div id="app-header">
				<Header expanded={expanded} setExpanded={setExpanded} />
			</div>
			<Routes>
				{/* Page not found */}
				<Route
					path="*"
					element={
						<div id="app-center">
							<h1>Sorry!</h1>
							<p>Page not found</p>
						</div>
					}
				/>
				{/* Home feeds */}
				<Route path="/" element={<HomeFeed />} />
				<Route path="/feed/:communityID" element={<SingleFeed />} />
				<Route path="/search/:searchField" element={<SearchFeed />} />

				{/* auth */}
				<Route path="/forgotPassword" element={<ForgotPassword />} />
				<Route
					path="/forgotPassword/verify"
					element={<ForgotPasswordVerify />}
				/>
				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="/verify" element={<VerifyPhone />} />
				<Route
					path="/email-verification/:email/:userID/:code"
					element={<EmailVerification />}
				/>

				{/* focus */}
				<Route path="/focus/post/:postID" element={<FocusPost />} />

				<Route path="/focus/discussion/:postID" element={<FocusDiscussion />} />

				<Route path="/focus/community/:communityID" element={<FocusGroup />} />
				<Route path="/focus/report/:postID/:type" element={<FocusReport />} />

				{/* community */}
				<Route path={"/community/join"} element={<JoinCommunity />} />
				<Route path={"/community/create"} element={<CreateCommunity />} />

				{/* settings */}
				<Route path="/settings" element={<Settings />} />
				<Route path="/settings/profile" element={<ProfileSettings />} />

				{/* Profile */}
				<Route path="/profile/:profileID/:isOrg" element={<ProfileScreen />} />
				<Route path="/inbox" element={<Inbox />} />

				{/* posting */}
				<Route path={"/post/create/:postID"} element={<CreatePost />} />
				<Route path={"/post"} element={<BeHeard />} />

				{/* payments */}
				<Route
					path="/payments/activateAccount"
					element={<AccountActivation />}
				/>
			</Routes>
			<MobileFooter expanded={expanded} setExpanded={setExpanded} />
		</div>
	);
}
