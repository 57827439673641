// File: Admin preferences component
// Default
import React, { useState } from "react";
// API
import communityApi from "../../api/community/main";
// Components
import PreferenceButton from "./preferenceButton";

export default function AdminPreferences({ communityID, adminInfo }) {
	// Specific
	const [visible, setVisible] = useState(adminInfo.isPublic);
	// Requests
	const toggleVisible = async () => {
		const result = await communityApi.settings.toggleVisible(communityID);
		if (result.ok) {
			setVisible(!visible);
		}
	};

	return (
		<div className='preferences-container'>
			{/* <PreferenceButton
				type={"navigate"}
				title={"Integrations"}
				navigateDestination={"/community/integrations"}
			/> */}
			<PreferenceButton
				type='switch'
				title={"Public"}
				switchBool={visible}
				setSwitchBool={setVisible}
				switchOnMsg={"Members are able search for this Community"}
				switchOffMsg={"Members are not able search for this Community"}
				toggleSwitch={() => toggleVisible()}
			/>
		</div>
	);
}
