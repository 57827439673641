// File: Inbox screen for invitations and notifications
// Default
import React, { useEffect, useState } from "react";
// Nav
import { useNavigate } from "react-router-dom";
// API
import communityApi from "../../api/community/main";
import usersApi from "../../api/user/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import CommunityCard from "../../components/cards/communityCard";
// Assets
import { Close, Delete } from "@material-ui/icons";
// Libraries
import { Button, useToast } from "@chakra-ui/react";
import PeopleIcon from "@mui/icons-material/People";
import ArticleIcon from "@mui/icons-material/Article";
import CampaignIcon from "@mui/icons-material/Campaign";
export default function Inbox() {
	// Loading
	const [loading, setLoading] = useState(true);
	const toast = useToast();
	// Nav
	const navigate = useNavigate();
	// Specific
	const [invitedGroups, setInvitedGroups] = useState([]);
	const [notifications, setNotifications] = useState([]);
	// Requests
	const getInvitedGroups = async () => {
		setLoading(true);
		const result = await communityApi.feed.invitedGroups();

		if (result.ok) {
			setInvitedGroups(result.data);
		}
		getNotifications();
	};

	const deleteInvitation = async (communityID) => {
		const result = await communityApi.members.rejectInvite(communityID);
		if (result.ok) {
			toast({
				title: "",
				description: "Deleted",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
			getInvitedGroups();
		}
	};

	const getNotifications = async () => {
		const result = await usersApi.data.notifications();
		if (result.ok) {
			setNotifications(result.data);
		}
		setLoading(false);
	};

	const deleteNotification = async (index) => {
		const result = await usersApi.data.deleteNotification(index);
		if (result.ok) {
			toast({
				title: "",
				description: "Deleted",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
			getNotifications();
		}
	};

	const clearNotifications = async (index) => {
		const result = await usersApi.data.clearNotifications();
		if (result.ok) {
			toast({
				title: "",
				description: "Cleared",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
			getNotifications();
		}
	};
	// Functions
	const pickGroup = (groupID) => {
		navigate(`/focus/community/${groupID}`);
	};
	// Use Effects
	useEffect(() => {
		getInvitedGroups();
	}, []);
	// menu
	const menuButtons = [
		{
			icon: <PeopleIcon />,
			text: "Invitations",
			clickHandler: () => console.log("clicked"),
		},
		// {
		// 	icon: <CampaignIcon />,
		// 	text: "Announcements",
		// 	clickHandler: () => console.log("clicked"),
		// },
		{
			icon: <ArticleIcon />,
			text: "Notifications",
			clickHandler: () => console.log("clicked"),
		},
	];
	return (
		<>
			<div id='app-left'>
				<h2>Inbox</h2>
				{/* {menuButtons.map((item) => {
					return (
						<button
							className='card-list-item'
							onClick={() => {
								item.clickHandler();
							}}
						>
							{item.icon}
							<p>{item.text}</p>
						</button>
					);
				})} */}
				{invitedGroups.length === 0 && (
					<div className='mt-L'>
						<h6>Communities Invitations Will Appear Here.</h6>
						<p>Looks like you don't have any invites at the moment.</p>
					</div>
				)}
				{invitedGroups.length > 0 && (
					<div className='inbox'>
						<h4>You've Been Invited To:</h4>
						<p>Click To View</p>
						{invitedGroups.map(function (item, index) {
							return (
								<div className='community-invite'>
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
										onClick={pickGroup}
									/>
									<button
										onClick={() => {
											deleteInvitation(item._id);
										}}
									>
										<Delete className='secondary' />
									</button>
								</div>
							);
						})}
					</div>
				)}

				<button
					className='mt'
					onClick={() => {
						navigate("/community/join");
					}}
				>
					<p className='link'>Join more communities today</p>
				</button>
			</div>
			<div id='app-center' className='inbox '>
				<LoadingWrapper loading={loading}>
					{notifications.length > 0 ? (
						<div className='section-card'>
							<div className='flex-between'>
								<h4>Notifications</h4>
								<Button
									variant='text'
									leftIcon={<Close />}
									onClick={() => {
										clearNotifications();
									}}
								>
									Clear All Notifications
								</Button>
							</div>
							{notifications.map(function (item, index) {
								return (
									<button
										className='community-invite std-card'
										onClick={() => {
											if (item.type === "vote" || item.type === "reply") {
												navigate(`/focus/${item.postType}/${item.postID}`);
											}
										}}
									>
										<div>
											<h5>{item.title}</h5>
											<p>{item.description}</p>
										</div>
										{/* <button
											onClick={(e) => {
												e.stopPropagation();
												deleteNotification(index);
											}}
										>
											<Close className='secondary' />
										</button> */}
									</button>
								);
							})}
						</div>
					) : (
						<div>
							<h6>Your Notifications will appear here.</h6>
							<p>Looks like you don't have any notifications at the moment.</p>
						</div>
					)}
				</LoadingWrapper>
			</div>
		</>
	);
}
