// File: Card for discussions
// Default
import React, { useState, useEffect } from "react";
// Nav
import { useNavigate } from "react-router-dom";
// API
import postsApi from "../../api/posts/main.js";
// Components
import CardHeader from "./cardComponents/cardHeader.js";
import CardBody from "./cardComponents/cardBody.js";
// Assets
import CardFooter from "./cardComponents/cardFooter.js";
import LoadingWrapper from "../../helper/loadingWrapper.js";

export default function DiscussionCard({ item }) {
	// Nav
	const navigate = useNavigate();
	// Specific
	const [subDiscussions, setSubDiscussions] = useState([]);
	// Requests
	const getDiscussions = async (parentType) => {
		const result = await postsApi.newFeeds.discussions({
			identifier: item.postInfo.postID,
			parentType: "discussion",
		});
		if (!result.ok) {
		} else {
			setSubDiscussions(result.data);
		}
	};
	// Use Effects
	useEffect(() => {
		getDiscussions();
	}, []);

	return (
		<button
			className={`card discussion-card`}
			onClick={() => {
				navigate("/focus/discussion/" + item.postInfo.postID);
			}}
		>
			<div className='card-content'>
				<div className='discussion-header'>
					<CardHeader
						name={item.userInfo.name}
						username={item.userInfo.username}
						pic={item.userInfo.pic}
						cardUserID={item.userInfo.userID}
						date={item.dateInfo.fromNow}
						type={"discussion"}
					/>
				</div>
				<CardBody
					title=''
					body={item.postInfo.title}
					isTruncated={item.postInfo.isTruncated}
				/>
			</div>
			<CardFooter
				totalVotes={item.footerInfo.votes}
				chats={item.footerInfo.chats}
				postID={item.postInfo.postID}
				type={"discussion"}
			/>

			<div style={{ paddingLeft: 10 }}>
				{subDiscussions.map(function (item, idx) {
					return (
						<li key={idx} className='sub-discussion'>
							<DiscussionCard item={item} />
						</li>
					);
				})}
			</div>
		</button>
	);
}
