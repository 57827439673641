// File: Card for communities
// Default
import React, { useState } from "react";
// API
import usersApi from "../../api/user/main.js";
// Nav
import { useNavigate } from "react-router-dom";
// Assets
import {
	ArrowDropDown,
	ArrowDropUp,
	MoreVert,
	People,
	Settings,
	Share,
	StarBorder,
	StarOutlined,
} from "@mui/icons-material";
import noGroupPic from "../../assets/images/no_group_pic.png";
import GlobalPic from "../../assets/images/global_pic.png";

// Theming
import "../../styles/components/cards/_communityCard.scss";
// Libraries
import {
	Avatar,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	useToast,
} from "@chakra-ui/react";
export default function CommunityCard({
	groupID,
	name,
	pic,
	isAdmin,
	subGroups,
	onClick,
	selected,
	// not always
	favorite,
	withOptions,
}) {
	//toast
	const toast = useToast();
	// Specific
	const [expanded, setExpanded] = useState(false);
	// Nav
	const navigate = useNavigate();

	// Requests
	const toggleFavorite = async () => {
		// TODO: client side handling of toggle to prevent requiring a reload to see state change
		if (favorite) {
			await usersApi.profile.removeFavorite(groupID);
		} else {
			await usersApi.profile.addFavorite(groupID);
		}
	};
	// Functions
	const viewCommunityClickHandler = () => {
		onClick(groupID);
	};
	const settingsClickHandler = () => {
		navigate(`/focus/community/${groupID}`);
	};
	const communityCodeClickHandler = () => {
		navigator.clipboard.writeText(`https://beta.quae.app/feed/${groupID}`);
		toast({
			title: "Copied to Clipboard.",
			description: "Share this link with friends!",
			status: "info",
			duration: 5000,
			isClosable: true,
			position: "top",
		});
	};

	// Components
	const PopOverButton = ({ text, icon, clickHandler }) => {
		return (
			<button
				className='card-list-item'
				onClick={(e) => {
					e.stopPropagation();
					clickHandler();
				}}
			>
				{icon}
				<p>{text}</p>
			</button>
		);
	};
	const Options = () => {
		return (
			<div>
				<Popover>
					<PopoverTrigger>
						<button
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<MoreVert />
						</button>
					</PopoverTrigger>
					<Portal>
						<PopoverContent>
							<PopoverArrow />
							<PopoverHeader>Community Options</PopoverHeader>
							<PopoverBody>
								<div>
									<PopOverButton
										text={"Community Feed"}
										icon={<People />}
										clickHandler={viewCommunityClickHandler}
									/>
									<PopOverButton
										text={"Settings"}
										icon={<Settings />}
										clickHandler={settingsClickHandler}
									/>
									<PopOverButton
										text={"Share"}
										icon={<Share />}
										clickHandler={communityCodeClickHandler}
									/>
									<PopOverButton
										text={
											favorite ? "Remove from favorites" : "Add to favorite"
										}
										icon={favorite ? <StarOutlined /> : <StarBorder />}
										clickHandler={toggleFavorite}
									/>
								</div>
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			</div>
		);
	};

	return (
		<>
			<button
				className={`group-card ${selected ? "selected" : ""}`}
				onClick={(e) => {
					e.stopPropagation();
					onClick(groupID, name, pic);
				}}
			>
				<div className='content'>
					<div className='avatar-wrapper'>
						{groupID === "Global" ? (
							<Avatar name={name} src={GlobalPic} className={"avatar"} />
						) : (
							<Avatar
								name={name}
								src={pic && pic !== "none" ? pic : noGroupPic}
								className={"avatar"}
							/>
						)}
						{isAdmin && <div className='admin-indicator' />}
					</div>
					<p>{name}</p>
				</div>
				<div className='icon-container'>
					{subGroups.length > 0 && (
						<button
							onClick={(e) => {
								e.stopPropagation();
								setExpanded(!expanded);
							}}
						>
							{!expanded && <ArrowDropDown />}
							{expanded && <ArrowDropUp />}
						</button>
					)}
					{groupID !== "Global" && withOptions && <Options />}
				</div>
			</button>
			<div>
				{expanded && (
					<>
						{subGroups.map(function (item, idx) {
							return (
								<div className='sub'>
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
										onClick={onClick}
										withOptions={withOptions}
									/>
								</div>
							);
						})}
					</>
				)}
			</div>
		</>
	);
}
