let themeStyle = {
	TEXT_COLOR_WHITE: "#fff",
	TEXT_COLOR_BLACK: "#000",
	TEXT_SIZE_EXTRA_SMALL: 12,
	TEXT_SIZE_SMALL: 14,
	TEXT_SIZE_DEFAULT: 16,
	TEXT_SIZE_MEDIUM: 20,
	TEXT_SIZE_LARGE: 24,
	TEXT_SIZE_EXTRA_LARGE: 28,

	FONT_EXTRA_SMALL: "Roboto_400Regular",
	FONT_DEFAULT: "Roboto_400Regular",
	FONT_DEFAULT_LIGHT: "Roboto_300Light",

	FONT_ACCENT_LIGHT: "Montserrat_300Light",
	FONT_ACCENT_DEFAULT: "Montserrat_400Regular",
	FONT_ACCENT_SEMIBOLD: "Montserrat_600SemiBold",
	FONT_ACCENT_BOLD: "Montserrat_700Bold",
	// ICONS
	SIZE_ICON_DEFAULT: 30,
	// COLORS
	// TEXT & BUTTON COLOR

	COLOR_TEXT_LIGHT: "#000",
	COLOR_TEXT_DARK: "#FFF",

	COLOR_TEXT_SECONDARY_LIGHT: "#111",
	COLOR_TEXT_SECONDARY_DARK: "#eee",

	COLOR_TEXT_INPUT_DARK: "#333",
	COLOR_TEXT_INPUT_LIGHT: "#ddd",

	COLOR_BUTTON_DARK: "#eee",
	COLOR_BUTTON_LIGHT: "#ddd",

	COLOR_BUTTON_DARK_HOVER: "#000",
	COLOR_BUTTON_LIGHT_HOVER: "#fff",

	BTN_CONTAINED_BG_DARK: "#E2E2E2",
	BTN_CONTAINED_BG_LIGHT: "#444",

	BTN_CONTAINED_BG_HOVER_DARK: "#FFF",
	BTN_CONTAINED_BG_HOVER_LIGHT: "#000",

	BTN_CONTAINED_BORDER_DARK: "#3A3A3A",
	BTN_CONTAINED_BORDER_LIGHT: "#BCBCBC",

	BTN_CONTAINED_BORDER_HOVER_DARK: "#E2E2E2",
	BTN_CONTAINED_BORDER_HOVER_LIGHT: "#E2E2E2",

	BTN_OUTLINED_BORDER_DARK: "#C0C0C0",
	BTN_OUTLINED_BORDER_LIGHT: "#454545",
	BTN_OUTLINED_BORDER_HOVER_DARK: "#FFF",
	BTN_OUTLINED_BORDER_HOVER_LIGHT: "#000",

	BTN_DISABLED_DARK: "#aaa",
	BTN_DISABLED_LIGHT: "#DDD",
	BTN_DISABLED_DARK_TEXT: "#777",
	BTN_DISABLED_LIGHT_TEXT: "#999",

	// PURPLE
	COLOR_PRIMARY_DEFAULT: "#6840D9",
	//#6840D9
	// #8D35D1
	COLOR_PRIMARY_LIGHT: "#8F67FF",
	COLOR_PRIMARY_DARK: "#59009F",
	// BLUE
	COLOR_ACCENT1_DEFAULT: "#55BDDE",
	COLOR_ACCENT1_LIGHT: "#8ED6FF",
	COLOR_ACCENT1_DARK: "#0085BE",
	// PINK
	COLOR_ACCENT2_DEFAULT: "#F41690",
	COLOR_ACCENT2_LIGHT: "#FF61C0",
	COLOR_ACCENT2_DARK: "#BC0063",
	// BACKGROUND/FOREGROUND
	COLOR_BACKGROUND_DARK: "#151515",
	COLOR_FOREGROUND_DARK: "#212121",
	COLOR_BACKGROUND_LIGHT: "#FFFFFF",
	COLOR_FOREGROUND_LIGHT: "#F4F4F4",
	// COLOR_INACTIVE: "#777",
	COLOR_BORDER: "#888",
	COLOR_BORDER_LIGHT: "#DBDBDB",
	COLOR_BORDER_DARK: "#424242",
	COLOR_URGENT: "#ff7878",
	COLOR_DANGER: "#ff0019",
	COLOR_SUCCESS: "#25c448",
	// GRADIENTS

	GRADIENT_PRIMARY: ["#6840D9", "#8F67FF"],
	// GRADIENT_PRIMARY: ["#59009F", "#8D35D1", "#C167FF"],

	GRADIENT_ACCENT1: ["#55BDDE", "#8ED6FF"],
	GRADIENT_ACCENT2: ["#FF61C0", "#FF61C0"],
	GRADIENT_URGENT: ["#ed7286", "#e0586e"],
	GRADIENT_SUCCESS: ["#25c448", "#53c96c"],
	// GRADIENT_LOGO: ["#F41690", "#8D35D1", "#55BDDE"],
	GRADIENT_LOGO: ["#F41690", "#6840D9", "#55BDDE"],
	GRADIENT_DISABLED: ["#777777", "#999999"],
};

export default themeStyle;
