import { useMemo, useState } from "react";

const AllData = () => {
	const [userID, setUserID] = useState("");
	const [loggedIn, setLoggedIn] = useState(false);

	const provider = useMemo(
		() => ({
			loggedIn,
			setLoggedIn,
			userID,
			setUserID,
		}),
		[loggedIn, setLoggedIn, userID, setUserID]
	);
	return provider;
};

export default AllData;
