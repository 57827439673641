// File: See all of your blocked users
// Default
import React, { useEffect, useState } from "react";
// API
import usersApi from "../../api/user/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import MemberCard from "../cards/memberCard";
// Assets
import { MoreVert } from "@material-ui/icons";

export default function SupportingUsers({ supportingUsersRef }) {
	// Loading
	const [loading, setLoading] = useState(true);
	// Specific
	const [supportingUsers, setSupportingUsers] = useState([]);
	// Requests
	const getBlockedUsers = async () => {
		setLoading(true);
		const result = await usersApi.data.supportingUsers();
		if (result.ok) {
			setSupportingUsers(result.data);
		}
		setLoading(false);
	};
	// Use Effects
	useEffect(() => {
		getBlockedUsers();
	}, []);
	return (
		<div id='blocked-users' ref={supportingUsersRef} className='section-card'>
			<h3>Supporting users</h3>
			<LoadingWrapper loading={loading}>
				{supportingUsers.length === 0 && (
					<div>
						<h6>No supporting users</h6>
						<p>Click on someone's profile to support them</p>
					</div>
				)}
				{supportingUsers.map(function (item, idx) {
					return (
						<li key={idx}>
							<MemberCard
								name={item.name}
								username={item.username}
								pic={item.pic}
								cardUserID={item.userID}
								isOrg={item.isOrg}
							/>
						</li>
					);
				})}
			</LoadingWrapper>
		</div>
	);
}
