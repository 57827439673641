// File: Reusable image upload
// Default
import React from "react";
// Assets
import EditIcon from "@mui/icons-material/Edit";
// Libraries
import { Avatar } from "@chakra-ui/react";

export default function UploadPicture({
	uploadPic,
	currentPic,
	newPic,
	setNewPic,
}) {
	// Functions
	const onImageChange = (e) => {
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setNewPic({
				image: file,
				imagePreviewUrl: reader.result,
			});
		};
		reader.readAsDataURL(file);
	};
	return (
		<div>
			<h5 className='center'>Upload New Picture</h5>

			<div className='avatar-container'>
				<Avatar
					className='avatar'
					alt='failed'
					src={newPic ? newPic.imagePreviewUrl : currentPic}
				/>
				<div className='upload-btn'>
					<label for='file-input'>
						<EditIcon />
					</label>
					<input
						type='file'
						id='file-input'
						accept='image/*'
						onChange={onImageChange}
					/>
				</div>
			</div>
		</div>
	);
}
