// File: Profile settings screen
// Default
import React, { useRef } from "react";
// Components
import ProfileSettingsComponent from "../../components/settings/editProfile";
import AddEmails from "../../components/settings/addEmails.js";
import BlockedUsers from "../../components/settings/blockedUsers.js";
import Subscriptions from "../../components/settings/subscription.js";
import ActivateAccount from "../../components/settings/activateAccount.js";
// Assets
import PersonIcon from "@mui/icons-material/Person";
import { BlockOutlined, CardMembership, Mail } from "@material-ui/icons";
import SupportingUsers from "../../components/settings/supportingUsers";
import { People } from "@mui/icons-material";

export default function ProfileSettings() {
	// Specific
	const containerRef = useRef(null);
	const profileSettingsRef = useRef(null);
	const emailRef = useRef(null);
	const subscriptionRef = useRef(null);
	const blockedUsersRef = useRef(null);
	const supportingUsersRef = useRef(null);
	const activationRef = useRef(null);
	const settingsButtons = [
		{
			icon: <PersonIcon />,
			text: "Account",
			scrollTo: profileSettingsRef,
		},
		{
			icon: <Mail />,
			text: "Emails",
			scrollTo: emailRef,
		},
		{
			icon: <BlockOutlined />,
			text: "Blocked Users",
			scrollTo: blockedUsersRef,
		},
		{
			icon: <People />,
			text: "Supporting Users",
			scrollTo: supportingUsersRef,
		},
		{
			icon: <CardMembership />,
			text: "Subscriptions",
			scrollTo: subscriptionRef,
		},
		{
			icon: <PersonIcon />,
			text: "Activate Account",
			scrollTo: activationRef,
		},
	];

	// Functions
	function scrollIntoView(target) {
		//objective: scroll the target element into view by getting its relative
		//position in the parent and settings the parents scrollTop
		let containerStart = containerRef.current.offsetTop;
		// 1- distance to the top of the parent element from the top of the screen
		let dist = target.current.offsetTop;
		// 2-distance from top of parent to the top of the target
		let scrollDist = dist - containerStart;
		// 3- distance that the parent container needs to scroll to
		containerRef.current.scrollTop = scrollDist;
	}

	return (
		<>
			<div id='app-left'>
				<h2>Profile Settings</h2>
				{settingsButtons.map((item) => {
					return (
						<button
							className='card-list-item'
							onClick={() => {
								scrollIntoView(item.scrollTo);
							}}
						>
							{item.icon}
							<p>{item.text}</p>
						</button>
					);
				})}
			</div>
			<div id='app-center' className='profile-settings' ref={containerRef}>
				<ProfileSettingsComponent profileSettingsRef={profileSettingsRef} />
				<AddEmails emailRef={emailRef} />
				<BlockedUsers blockedUsersRef={blockedUsersRef} />
				<SupportingUsers supportingUsersRef={supportingUsersRef} />
				<Subscriptions subscriptionRef={subscriptionRef} />
				<ActivateAccount activationRef={activationRef} />
				<div className='bottom'></div>
			</div>
		</>
	);
}
