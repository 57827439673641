// File: Editing the options for a poll
// Default
import React, { useState } from "react";
// Assets
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// Libraries
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";

export default function SetPollOptions({
	setOptionsString,
	optionsString,
	optionsType,
}) {
	// Specific
	const [option, setOption] = useState("");
	const [optionsChanging, setOptionsChanging] = useState(false);
	// Functions
	const handleSubmit = () => {
		if (optionsString.length < 10 && option !== "") {
			AddItemsToOptions(option);
			setOption("");
		}
	};
	const AddItemsToOptions = (newOption) => {
		setOptionsString([...optionsString, ...[option]]);
	};
	const RemoveItemFromOptions = (name) => {
		function filter() {
			let newArray = optionsString;
			let doneArray = newArray.filter((el) => el !== name);
			return doneArray;
		}
		setOptionsString(filter());
	};

	async function shiftUpWrapper(index) {
		setOptionsChanging(true);
		await shiftUp(index);
		setOptionsChanging(false);
	}
	async function shiftDownWrapper(index) {
		setOptionsChanging(true);
		await shiftDown(index);
		setOptionsChanging(false);
	}

	function shiftUp(index) {
		if (index !== 0) {
			let tempArray = optionsString;
			let tempOption = tempArray[index];
			tempArray[index] = tempArray[index - 1];
			tempArray[index - 1] = tempOption;
			setOptionsString(tempArray);
		}
	}
	function shiftDown(index) {
		if (index !== optionsString.length - 1) {
			let tempArray = optionsString;
			let tempOption = tempArray[index];
			tempArray[index] = tempArray[index + 1];
			tempArray[index + 1] = tempOption;
			setOptionsString(tempArray);
		}
	}

	return (
		<div className='set-poll-options'>
			<div className='header'>
				<h3>Poll Options</h3>
				<Button
					variant='text'
					onClick={() => {
						setOptionsString([]);
					}}
				>
					Clear Current Options
				</Button>
			</div>

			<div className='option-container'>
				{optionsType !== "Petition" && (
					<>
						{!optionsChanging && (
							<>
								<>
									{optionsString.map(function (d, idx) {
										return (
											<li key={idx} className='option'>
												<p>{d}</p>
												<div className='buttons'>
													<button onClick={() => shiftUpWrapper(idx)}>
														<ArrowUpwardIcon className='secondary' />
													</button>
													<button onClick={() => shiftDownWrapper(idx)}>
														<ArrowDownwardIcon className='secondary' />
													</button>
													<button
														onClick={() => {
															RemoveItemFromOptions(d);
														}}
													>
														<DeleteIcon className='secondary' />
													</button>
												</div>
											</li>
										);
									})}
									{optionsString.length < 2 && optionsType !== "Add" && (
										<p className='center'>Posts must have at least 2 options</p>
									)}
									{optionsString.length < 2 && optionsType === "Add" && (
										<p className='center'>
											You can add your own options, but it's not required
										</p>
									)}
								</>

								{optionsType !== "Petition" && (
									<div className='text-field-wrapper add-options-container'>
										<InputGroup>
											<Input
												value={option}
												onChange={(e) => {
													setOption(e.target.value);
												}}
												onKeyPress={(e) => {
													if (e.key === "Enter") {
														handleSubmit();
													}
												}}
												placeholder='Add another option...'
											/>
											<InputRightElement>
												<Button
													variant='contained'
													disabled={option.length === 0}
													onClick={() => handleSubmit()}
												>
													<AddIcon className='icon-white' />
												</Button>
											</InputRightElement>
										</InputGroup>
									</div>
								)}
							</>
						)}
					</>
				)}
				{optionsType === "Petition" && (
					<div className='std-card mt'>
						<p className='center'>
							Petitions don't have options. People will opt to sign their name
						</p>
					</div>
				)}
			</div>
		</div>
	);
}
