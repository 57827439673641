// File: Explore public communities and search new communities to join
// Default
import React, { useState, useEffect } from "react";
// Nav
import { useNavigate } from "react-router-dom";
// API
import communityApi from "../../api/community/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Components
import Tabs from "../../components/tabs";
import CommunityCard from "../../components/cards/communityCard";
// Assets
import SearchIcon from "@mui/icons-material/Search";
// Libraries
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";

export default function JoinCommunity() {
	// Loading
	const [loading, setLoading] = useState(true);
	const [searchLoading, setSearchLoading] = useState(false);
	// Nav
	const navigate = useNavigate();
	// Specific
	const tabs = [
		{ text: "Join", onClick: () => navigate("/community/join"), focused: true },
		{
			text: "Create",
			onClick: () => navigate("/community/create"),
			focused: false,
		},
	];
	const [searchField, setSearchField] = useState("");
	const [searchGroups, setSearchGroups] = useState([]);
	const [popularGroups, setPopularGroups] = useState([]);
	const [byEmailGroups, setByEmailGroups] = useState([]);
	const [popularSubGroups, setPopularSubGroups] = useState([]);
	// Requests
	const getSuggestedGroups = async () => {
		setLoading(true);
		const result = await communityApi.feed.suggestedGroups();
		if (result.ok) {
			setPopularGroups(result.data.popular);
			setByEmailGroups(result.data.byEmail);
			setPopularSubGroups(result.data.popularSub);
		}
		setLoading(false);
	};

	const search = async () => {
		setSearchLoading(true);
		const result = await communityApi.feed.searchPublicGroups(searchField);
		if (result.ok) {
			setSearchGroups(result.data);
		}
		setSearchLoading(false);
	};

	// Use Effects
	useEffect(() => {
		getSuggestedGroups();
	}, []);
	return (
		<div id='app-center'>
			<h2>Join a New Community</h2>
			<Tabs tabs={tabs} />
			<div className='section-card mt'>
				<h4>Search Communities</h4>
				<InputGroup>
					<Input
						onChange={(e) => {
							setSearchField(e.target.value);
						}}
						value={searchField}
						onKeyPress={(e) => {
							if (e.key === "Enter" && searchField.length > 0) {
								search();
							}
						}}
						placeholder='Search by name, communityID...'
					/>
					<InputRightElement>
						<Button
							variant='contained'
							disabled={searchField.length === 0}
							onClick={() => search()}
						>
							<SearchIcon className='icon-white' />
						</Button>
					</InputRightElement>
				</InputGroup>
				<LoadingWrapper loading={searchLoading}>
					{searchGroups.length === 0 && (
						<div className='std-card center'>No results</div>
					)}
					{searchGroups.length > 0 && (
						<>
							<h5>Search Results</h5>
							{searchGroups.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
									/>
								);
							})}
						</>
					)}
				</LoadingWrapper>
				<h3 className='mt mb'>Suggested Communities</h3>
				<LoadingWrapper loading={loading}>
					{popularGroups.length > 0 && (
						<>
							<h5>Popular Communities</h5>
							{popularGroups.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
									/>
								);
							})}
						</>
					)}
					{popularSubGroups.length > 0 && (
						<>
							<h5>Sub Communities Available To You</h5>
							{popularSubGroups.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
									/>
								);
							})}
						</>
					)}
					{byEmailGroups.length > 0 && (
						<div>
							<h5>Communities Available To You Email</h5>
							{byEmailGroups.map(function (item, index) {
								return (
									<CommunityCard
										name={item.name}
										pic={item.pic}
										communityID={item.communityID}
										subGroups={item.subGroups}
										joined={item.joined}
										isAdmin={item.isAdmin}
										groupID={item._id}
									/>
								);
							})}
						</div>
					)}
				</LoadingWrapper>
			</div>
		</div>
	);
}
