// File: Verifying your phone on sign up

// Default
import React, { useState, useContext } from "react";
import { Context } from "../../providers/Provider";

// Nav
import { useLocation, useNavigate } from "react-router-dom";

// API
import usersApi from "../../api/user/main";

// Libraries
import { Button, Input, useToast } from "@chakra-ui/react";

export default function VerifyPhone(props) {
	// Default
	const [loading, setLoading] = useState(false);
	const { setLoggedIn, setUserID } = useContext(Context);
	const toast = useToast();
	// Nav
	const navigate = useNavigate();
	const location = useLocation();
	// Specific
	const [code, setCode] = useState("");
	// Requests
	const phoneLast = async () => {
		setLoading(true);
		const result = await usersApi.auth.createAccount({
			phone: location.state,
			code,
		});
		if (result.ok) {
			const data = result.data;
			if (data.token) {
				await localStorage.setItem("token", result.data.token);
				setLoggedIn(true);
				setUserID(result.data.userID);
				navigate("/settings/profile");
			} else {
				toast({
					title: "Error.",
					description: result.data,
					status: "error",
					duration: 5000,
					isClosable: true,
					position: "top",
				});
			}
		}
		setLoading(false);
	};

	return (
		<div className='sign-up'>
			<div className='shadow-card'>
				<div className='w-80 title'>
					<h3 className='center'>Verify Account</h3>
					<hr />
				</div>
				<div className='text-field-wrapper'>
					<Input
						variant='filled'
						onChange={(e) => {
							setCode(e.target.value);
						}}
						value={code}
						placeholder='Enter code...'
					/>
				</div>
				<div className='button-container'>
					<Button
						onClick={() => {
							if (code.length === 7) {
								phoneLast();
							} else {
								toast({
									title: "Error.",
									description: "Invalid code.",
									status: "error",
									duration: 5000,
									isClosable: true,
									position: "top",
								});
							}
						}}
						variant='contained'
						isLoading={loading}
					>
						Confirm
					</Button>
					<div className='links'>
						<p className='center'>
							Have an account? <a href='/account/login'>Log In</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
