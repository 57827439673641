// File: Right margin of initiative full screen, shows admins and members of the initiative
// Default
import React, { useEffect, useState } from "react";
// Nav
import { useParams } from "react-router-dom";
// API
import postsApi from "../../../api/posts/main";
// Helper
import LoadingWrapper from "../../../helper/loadingWrapper";
// Components
import MemberCard from "../../cards/memberCard";

export default function PostMembers() {
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const { postID } = useParams();
	// Specific
	const [admins, setAdmins] = useState([]);
	const [members, setMembers] = useState([]);
	// Requests
	const getMembers = async () => {
		setLoading(true);
		const result = await postsApi.post.data.getMembers(postID);
		if (result.ok) {
			setMembers(result.data);
		}
		setLoading(false);
	};
	const getAdmins = async () => {
		const result = await postsApi.post.data.getAdmins(postID);
		if (result.ok) {
			setAdmins(result.data);
		}
	};
	// Use Effects
	useEffect(() => {
		getMembers();
		getAdmins();
	}, []);
	return (
		<LoadingWrapper loading={loading}>
			<div className='community-right members section-card'>
				<h3>Admins</h3>
				{admins.map(function (item, index) {
					return (
						<MemberCard
							name={item.name}
							pic={item.pic}
							cardUserID={item.userID}
							username={item.username}
						/>
					);
				})}
				<h3>Voters</h3>
				{members.map(function (item, index) {
					return (
						<MemberCard
							name={item.name}
							pic={item.pic}
							cardUserID={item.userID}
							username={item.username}
						/>
					);
				})}
			</div>
		</LoadingWrapper>
	);
}
