// File: Reusable Card Footer
// Default
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// API
import postsApi from "../../../api/posts/main";
// Components
import OptionsModal from "./optionsModal";
// Assets
import ShareIcon from "@mui/icons-material/Share";
import MoreVert from "@mui/icons-material/MoreVert";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import FlagIcon from "@material-ui/icons/Flag";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// Libraries
import {
	Avatar,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	useToast,
} from "@chakra-ui/react";

export default function CardFooter({ totalVotes, chats, postID, type, voted }) {
	// Default
	const toast = useToast();
	// nav
	const navigate = useNavigate();
	// popover
	const [isOpen, setIsOpen] = useState(false);
	const open = (e) => {
		e.stopPropagation();
		setIsOpen(!isOpen);
	};
	const close = () => {
		setIsOpen(false);
	};
	// Requests
	const share = async () => {
		const result = await postsApi.options.share({ postID, type });
		if (result.ok) {
			navigator.clipboard.writeText(result.data);
			toast({
				title: "Copied to Clipboard.",
				description: "Share the link with your friends!",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};

	const reportHandler = () => {
		console.log("clicked");
		navigate(`/focus/report/${postID}/${type}`);
	};

	// Popover
	const PopOverButton = ({ text, icon, clickHandler }) => {
		return (
			<button
				className='card-list-item'
				onClick={(e) => {
					e.stopPropagation();
					clickHandler();
				}}
			>
				{icon}
				<p>{text}</p>
			</button>
		);
	};
	const Options = () => {
		return (
			<div>
				<Popover isOpen={isOpen} onClose={close}>
					<PopoverTrigger>
						<button onClick={open} className='item'>
							<MoreVert />
						</button>
					</PopoverTrigger>
					<Portal>
						<PopoverContent>
							<PopoverArrow />
							<PopoverHeader>Post Options</PopoverHeader>
							<PopoverBody>
								<div>
									<PopOverButton
										text={"Report Post"}
										icon={<FlagIcon className='danger' />}
										clickHandler={reportHandler}
									/>
									<PopOverButton
										text={"Copy Link"}
										icon={<ContentCopyIcon />}
										clickHandler={share}
									/>
								</div>
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			</div>
		);
	};
	return (
		<>
			<div className='card-footer'>
				<button className={`item ${voted ? "voted" : ""}`}>
					<HowToVoteIcon />
					<p>{totalVotes}</p>
				</button>
				<button className='item'>
					<ChatBubbleIcon />
					<p>{chats}</p>
				</button>

				<button
					className='item'
					onClick={(e) => {
						e.stopPropagation();
						share();
					}}
				>
					<ShareIcon />
				</button>
				<button onClick={open} className='item'>
					<Options />
				</button>
			</div>
		</>
	);
}
