// File: Edit your subscription status
// Default
import React from "react";

export default function BlockedUsers({ subscriptionRef }) {
	return (
		<div id='blocked-users' ref={subscriptionRef} className='section-card'>
			<h3>Subscriptions</h3>
			<div className='subscriptions'>
				<div className='subscription-card premium'>
					<h4>Premium</h4>
				</div>
				<div className='subscription-card PRO'>
					<h4>Pro</h4>
				</div>
			</div>
		</div>
	);
}
