import client from "../../client";
const endpoint = "/posts/post/create";

const newDraft = (identifier) =>
	client.post(`${endpoint}/newDraft`, { identifier });

const getDraft = (postID) => client.post(`${endpoint}/getDraft`, { postID });

const publish = (postID) => client.post(`${endpoint}/publish`, { postID });

const saveDraft = ({
	title,
	body,
	blocks,
	options,
	userID,
	isOrg,
	voteType,
	postID,
	communityID,
	tags,
}) =>
	client.post(`${endpoint}/saveDraft`, {
		title,
		body,
		blocks,
		options,
		userID,
		isOrg,
		voteType,
		postID,
		communityID,
		tags,
	});

const create = { newDraft, getDraft, publish, saveDraft };
export default create;
