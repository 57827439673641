import client from "../client";
const endpoint = "/posts/options";

const report = ({ postID, type, message }) =>
	client.post(`${endpoint}/report`, { postID, type, message });

const share = ({ postID, type }) =>
	client.post(`${endpoint}/share`, { postID, type });

const deleteDraft = ({ postID, type }) =>
	client.post(`${endpoint}/deleteDraft`, { postID, type });

const options = {
	report,
	share,
	deleteDraft,
};
export default options;
