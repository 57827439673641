// File: Footer when screen size is small enough for mobile
// Default
import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// API
import usersApi from "../../api/user/main";
// Components
import BeHeardButton from "./beHeardButton";
// Assets
import MenuIcon from "@mui/icons-material/Menu";
// Libraries
import { Avatar } from "@chakra-ui/react";
import MobileDrawer from "./mobileDrawer";

export default function MobileFooter() {
	// Default
	const { userID, loggedIn } = useContext(Context);
	// Nav
	const navigate = useNavigate();
	// Specific
	const [name, setName] = useState();
	const [profilePic, setProfilePic] = useState("none");
	// Requests
	const getUser = async () => {
		const result = await usersApi.data.profileInfo(userID);
		if (!result.ok) {
		} else {
			setProfilePic(result.data.profilePic);
			setName(result.data.name);
		}
	};
	// Use Effects
	useEffect(() => {
		if (loggedIn) {
			getUser();
		}
	}, [loggedIn]);

	return (
		<>
			<div className="container" id="mobile-footer">
				<div className="menu">
					<MobileDrawer />
				</div>
				<BeHeardButton />
				<div className="profile">
					<button
						onClick={() => {
							navigate(`/profile/${userID}/false`);
						}}>
						<Avatar name={name} src={profilePic} />
					</button>
				</div>
			</div>
		</>
	);
}
