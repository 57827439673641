// File: User auth logging in
// Default
import React, { useContext, useState } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// API
import usersApi from "../../api/user/main";
// Libraries
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	useToast,
} from "@chakra-ui/react";
export default function Login() {
	// Default
	const [loading, setLoading] = useState(false);
	const { setLoggedIn, setUserID } = useContext(Context);
	const toast = useToast();
	// Nav
	const navigate = useNavigate();
	// Specific
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [passwordShow, setPasswordShow] = useState(false);
	// Requests
	const loginWrapper = async () => {
		setLoading(true);
		const result = await usersApi.auth.login({
			phone,
			password,
		});
		if (result.ok) {
			if (result.data.token) {
				localStorage.setItem("token", result.data.token);
				const userID = await jwt_decode(result.data.token)._id;
				if (userID) {
					navigate("/");
					setLoggedIn(true);
					setUserID(userID);
				}
			} else {
				toast({
					title: "Error.",
					description: result.data,
					status: "error",
					duration: 5000,
					isClosable: true,
					position: "top",
				});
			}
		}
		setLoading(false);
	};

	return (
		<div id='app-center' className='login'>
			<h2 className='center'>Log In</h2>
			<div className='text-field-wrapper'>
				<FormControl>
					<FormLabel>Phone Number</FormLabel>
					<Input
						value={phone}
						onChange={(e) => {
							setPhone(e.target.value);
						}}
						placeholder='1235551234'
					/>
					<FormLabel>Password</FormLabel>
					<InputGroup>
						<Input
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									loginWrapper();
								}
							}}
							placeholder='Secret1!'
							type={passwordShow ? "text" : "password"}
						/>
						<InputRightElement>
							<Button onClick={() => setPasswordShow(!passwordShow)}>
								{passwordShow ? "Hide" : "Show"}
							</Button>
						</InputRightElement>
					</InputGroup>
					<Button
						onClick={() => loginWrapper()}
						variant='contained'
						isFullWidth
						disabled={phone.length === 0 || password.length === 0}
						isLoading={loading}
					>
						Login
					</Button>
					<div className='pt-L'>
						<p className='center link'>
							<button onClick={() => navigate(`/signup`)}>Sign Up</button>
						</p>

						<p className='pt center link'>
							<button onClick={() => navigate("/forgotPassword")}>
								Forgot Password?
							</button>
						</p>
					</div>
				</FormControl>
			</div>
		</div>
	);
}
