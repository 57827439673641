import options from "./options";
import vote from "./vote";
import newFeeds from "./newFeeds";

import discussion from "./discussion/main";

import post from "./post/main";

const postsApi = {
	newFeeds,
	options,
	vote,
	discussion,
	post,
};
export default postsApi;
