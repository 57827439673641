import client from "../client";
const endpoint = "/community/integrations";

const genDiscordKey = (groupID) =>
	client.post(`${endpoint}/genDiscordKey`, { groupID });

const getDiscordKey = (groupID) =>
	client.post(`${endpoint}/getDiscordKey`, { groupID });

const integrations = {
	genDiscordKey,
	getDiscordKey,
};
export default integrations;
