// File: Header of app
// Default
import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// API
import usersApi from "../../api/user/main";
// Components
import HistoryButtons from "./lefts/historyButtons";
// Assets
import QuaeLogo from "../../assets/images/quae_logo_color_transparent.png";
import { Mail, Settings } from "@material-ui/icons";
// Theming
import "../../styles/core/_header.scss";
import "../../styles/components/_beHeard.scss";
// Libraries
import {
	Avatar,
	Button,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Tooltip,
} from "@chakra-ui/react";
import MobileDrawer from "./mobileDrawer";
import { Search } from "@mui/icons-material";

export default function Header() {
	// Default
	const { userID, loggedIn } = useContext(Context);
	// Nav
	const navigate = useNavigate();
	// Specific
	const [invLength, setInvLength] = useState(0);
	const [name, setName] = useState("");
	const [profilePic, setProfilePic] = useState("");
	const [searchField, setSearchField] = useState("");
	// Requests
	const getUser = async () => {
		const result = await usersApi.data.profileInfo(userID);
		if (!result.ok) {
		} else {
			setProfilePic(result.data.profilePic);
			setName(result.data.name);
		}
	};
	// Use Effects
	useEffect(() => {
		if (loggedIn) {
			getUser();
		}
	}, [userID, loggedIn]);

	return (
		<header>
			<div className="left">
				<div className="hidden-mobile">
					<HistoryButtons />
				</div>
				<div className="visible-mobile">
					<MobileDrawer />
				</div>
				<div className="hidden-mobile">
					<Tooltip label="Home">
						<button
							className="group"
							onClick={() => {
								navigate("/");
							}}>
							<img
								name={"Quae"}
								src={QuaeLogo}
								className="logo"
								alt="Quae Logo"
							/>
							<h3 style={{ marginLeft: 10 }} class="no-wrap">
								Quae
							</h3>
						</button>
					</Tooltip>
				</div>
			</div>
			<div className="center">
				<div className="hidden-mobile">
					<InputGroup isFullWidth>
						<Input
							isFullWidth
							value={searchField}
							onChange={(e) => {
								setSearchField(e.target.value);
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									navigate(`/search/${searchField}`);
								}
							}}
							placeholder={"Search..."}
						/>
						<InputRightElement>
							<Button
								variant="contained"
								disabled={searchField.length === 0}
								onClick={() => {
									navigate(`/search/${searchField}`);
								}}>
								<Search className="icon-white" />
							</Button>
						</InputRightElement>
					</InputGroup>
				</div>
			</div>

			<div className="right">
				{loggedIn && (
					<Tooltip label="Profile">
						<button
							onClick={() => {
								navigate(`/profile/${userID}/${false}`);
							}}>
							<Avatar name={name} src={profilePic} className="profile" />
							{invLength !== 0 && (
								<div className="notification">
									<h4 className="center">{invLength.toString()}</h4>
								</div>
							)}
						</button>
					</Tooltip>
				)}
				{!loggedIn && (
					<div class="button-group right">
						<Button
							variant="outlined"
							onClick={() => {
								navigate("/login");
							}}>
							Login
						</Button>
						<Button
							variant="contained"
							onClick={() => {
								navigate("/signup");
							}}>
							Sign Up
						</Button>
					</div>
				)}
				{loggedIn && (
					<>
						<div>
							<Tooltip label="Inbox">
								<IconButton
									onClick={() => {
										navigate("/inbox");
									}}
									variant="filled">
									<Mail />
								</IconButton>
							</Tooltip>
						</div>
					</>
				)}
				<div>
					<Tooltip label="Settings">
						<IconButton
							onClick={() => {
								navigate("/settings");
							}}
							variant="filled">
							<Settings />
						</IconButton>
					</Tooltip>
				</div>
			</div>
		</header>
	);
}
