// File: Reusable Card Header
// Default
import React from "react";
// Nav
import { useNavigate } from "react-router-dom";
// Components
import CardType from "./cardType";
// Assets
import VoteCheck from "../../../assets/images/vote_check.png";
// Libraries
import { Avatar } from "@chakra-ui/react";

export default function CardHeader({
	name,
	username,
	pic,
	date,
	cardUserID,
	isOrg,
	voted,
	type,
	disabled,
	communityInfo,
}) {
	// Nav
	const navigate = useNavigate();
	return (
		<>
			{communityInfo && (
				<p>
					Posted to <i>{communityInfo.name}</i>
				</p>
			)}

			<div className="card-header">
				{cardUserID && (
					<button
						onClick={(e) => {
							e.stopPropagation();
							navigate(
								`/profile/${cardUserID}/${
									isOrg === undefined ? "false" : isOrg
								}`
							);
						}}
						disabled={disabled}>
						<Avatar name={name} src={pic} className="picture" />
					</button>
				)}

				<div className="text">
					<div className="left">
						{cardUserID && (
							<>
								<h6>{name}</h6>
								<p className="secondary">
									@{username || "user" + cardUserID.slice(0, 10)}
								</p>
							</>
						)}
					</div>
					<div className="right">
						<p>{date}</p>
						<div className="badges"></div>
					</div>
				</div>
			</div>
		</>
	);
}
