// File: report screen for posts
// default
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// libraries
import { Button, Textarea, useToast } from "@chakra-ui/react";
// API
import postsApi from "../../api/posts/main";
// assets
import SendIcon from "@mui/icons-material/Send";

export default function FocusReport({}) {
	// nav
	const navigate = useNavigate();
	// specific
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState();
	const [reported, setReported] = useState(false);
	const { postID, type } = useParams();
	// toast
	const toast = useToast();
	// requests
	const report = async () => {
		setLoading(true);
		const result = await postsApi.options.report({ postID, type, message });
		if (result.ok) {
			setReported(true);
			toast({
				title: "Report Submitted!",
				description: "We will review your report and take action if needed",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		} else {
			toast({
				title: "Uh Oh! Something went wrong.",
				description: "Please try again in a few minutes",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
		setLoading(false);
		navigate(-1);
	};
	return (
		<div id="app-center">
			<h2>Submit A Report</h2>
			<Textarea
				value={message}
				onChange={(e) => setMessage(e.target.value)}
				placeholder={"Tells us what's wrong"}
				isMultiline
			/>
			<Button
				isLoading={loading}
				loadingText="Submitting"
				variant="contained"
				onClick={() => {
					report();
				}}
				leftIcon={<SendIcon />}>
				Send Report
			</Button>
		</div>
	);
}
