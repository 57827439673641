// File: Viewing a single feed from a community
// Default
import React, { useEffect, useState } from "react";
// Nav
import { useNavigate, useParams } from "react-router-dom";
// Api
import postsApi from "../../api/posts/main";
import communityApi from "../../api/community/main";
// Components
import FeedCard from "../../components/cards/feedCard";
import CommunityCard from "../../components/cards/communityCard";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Assets
import noGroupPic from "../../assets/images/no_group_pic.png";
// Libraries
import { Avatar } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";

export default function SingleFeed() {
	// Loading
	const [loading, setLoading] = useState(false);
	const [refreshing, setRefreshing] = useState(false);
	const [skip, setSkip] = useState(0);
	const [bottomLoading, setBottomLoading] = useState(false);
	const [groupName, setGroupName] = useState("");
	const [groupPic, setGroupPic] = useState("");

	// Theming
	// Nav
	const { communityID } = useParams();
	const navigate = useNavigate();
	// Specific
	const [posts, setPosts] = useState([]);
	const [finished, setFinished] = useState(false);
	// Requests
	const singleFeed = async (fromBot) => {
		if (fromBot ? !finished : true) {
			async function getSkip() {
				if (fromBot === true) {
					let tempSkip = skip;
					setSkip(tempSkip + 1);
					setBottomLoading(true);
					return tempSkip + 1;
				} else {
					setPosts([]);
					setSkip(0);
					setLoading(true);
					return 0;
				}
			}
			if (fromBot ? !loading && !bottomLoading : !loading) {
				await getSkip().then(async (data) => {
					const result = await postsApi.newFeeds.singleFeed({
						communityID,
						skip: data,
					});
					if (result.ok) {
						if (fromBot) {
							setPosts([...posts, ...result.data]);
							if (result.data.length === 0) {
								setFinished(true);
							} else {
								setFinished(false);
							}
						} else {
							setPosts(result.data);
						}
					}
					setLoading(false);
					setBottomLoading(false);
				});
			}
		}
	};

	const getGroup = async () => {
		const result = await communityApi.data.groupInfo(communityID);
		if (!result.ok) {
		} else {
			setGroupPic(result.data.pic);
			setGroupName(result.data.name);
		}
	};

	// UseEffects
	//  UseEffects
	useEffect(() => {
		singleFeed();
		getGroup();
	}, [communityID]);

	return (
		<>
			<div id='app-left'>
				{/* Showing the community you're currently viewing */}

				<CommunityCard
					groupID={communityID}
					name={groupName}
					pic={groupPic}
					subGroups={[]}
					isAdmin={false}
					onClick={(groupID) => {
						navigate(`/focus/community/${communityID}`);
					}}
					selected={false}
				/>
			</div>
			<div id='app-center'>
				<LoadingWrapper loading={loading}>
					<InfiniteScroll
						dataLength={posts.length}
						next={() => {
							singleFeed(true);
						}}
						hasMore={!finished}
						loader={
							<LoadingWrapper loading={true}>
								<></>
							</LoadingWrapper>
						}
						height={"100vh"}
						className='home-feed'
						endMessage={
							<p style={{ textAlign: "center" }}>
								<b>Congrats! You've reached the end of the feed</b>
							</p>
						}
					>
						<div className='home-feed'>
							{/* Posts feed */}
							{posts.map(function (item, index) {
								return <FeedCard item={item} />;
							})}
							{/* Render when there are no posts */}
							{posts.length === 0 && !loading && (
								<div className='pt-L align-center'>
									<Avatar
										alt={groupName}
										src={
											groupPic && groupPic !== "none" ? groupPic : noGroupPic
										}
										className='avatar-large'
									/>
									<h2>Welcome to the {groupName} Community</h2>
									<h5>There Doesn't Seem To Be Anything To Vote On Yet</h5>
									<h5>Get Started By Making A Post</h5>
								</div>
							)}
						</div>
					</InfiniteScroll>
				</LoadingWrapper>
			</div>
		</>
	);
}
