// File: Left margin for profile screen, shows user content
// Default
import React, { useState, useEffect } from "react";
// Nav
import { useParams } from "react-router-dom";
// API
import usersApi from "../../../api/user/main.js";
// Helper
import LoadingWrapper from "../../../helper/loadingWrapper.js";
// Components
import UserContent from "./userContent.js";

export default function ProfileLeft() {
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const { profileID, isOrg } = useParams();
	// Specific
	const [profileInfo, setProfileInfo] = useState({
		pic: "",
		name: "",
		username: "",
		userID: "",
		isOrg: false,
		isIdVerified: false,
	});
	const [statsInfo, setStatsInfo] = useState({
		posts: 0,
		supporters: 0,
	});
	const [yourUserStatesInfo, setYourUserStatesInfo] = useState({
		isSupported: false,
		isBlocked: false,
	});
	// Requests
	const getProfile = async () => {
		setLoading(true);
		const result = await usersApi.data.profileContent({
			userID: profileID,
			isOrg,
		});
		if (result.ok) {
			const data = result.data;
			setProfileInfo(data.profileInfo);
			setStatsInfo(data.statsInfo);
			setYourUserStatesInfo(data.yourUserStatesInfo);
		}
		setLoading(false);
	};
	// Use Effects
	useEffect(() => {
		getProfile();
	}, [profileID]);

	return (
		<div className='section-card'>
			<LoadingWrapper loading={loading}>
				<UserContent
					profileInfo={profileInfo}
					statsInfo={statsInfo}
					yourUserStatesInfo={yourUserStatesInfo}
				/>
			</LoadingWrapper>
		</div>
	);
}
