import client from "../client";
const endpoint = "/user/profile";

const uploadProfilePic = (formData) =>
	client.post(`${endpoint}/uploadProfilePic`, formData);

const uploadName = (name) => client.post(`${endpoint}/uploadName`, { name });

const uploadBio = (bio) => client.post(`${endpoint}/uploadBio`, { bio });

const uploadUsername = (username) =>
	client.post(`${endpoint}/uploadUsername`, { username });

const addEmail = (email) => client.post(`${endpoint}/addEmail`, { email });

const resendEmail = (email) =>
	client.post(`${endpoint}/resendEmail`, { email });

const verifyEmail = ({ email, code, userID }) =>
	client.post(`${endpoint}/verifyEmail`, { email, code, userID });

const deleteEmail = (email) =>
	client.post(`${endpoint}/deleteEmail`, { email });

const getEmails = () => client.post(`${endpoint}/getEmails`);

const addFavorite = (communityID) =>
	client.post(`${endpoint}/addFavorite`, { communityID });

const removeFavorite = (communityID) =>
	client.post(`${endpoint}/removeFavorite`, { communityID });

const profile = {
	uploadName,
	uploadUsername,
	uploadBio,
	addEmail,
	verifyEmail,
	getEmails,
	addFavorite,
	removeFavorite,
	uploadProfilePic,
	deleteEmail,
	resendEmail,
};
export default profile;
