// File: Render image based on post type
// Default
import React from "react";
// Assets
import PollType from "../../../assets/images/postTypes/poll_type.svg";
import ProposalType from "../../../assets/images/postTypes/proposal_type.svg";
import InitiativeType from "../../../assets/images/postTypes/initiative_type.svg";
import AnnouncementType from "../../../assets/images/postTypes/announcement_type.svg";

export default function CardType({ type, size }) {
	let imgType = "none";
	if (type === "poll") {
		imgType = PollType;
	}
	if (type === "proposal") {
		imgType = ProposalType;
	} else if (type === "initiative") {
		imgType = InitiativeType;
	} else if (type === "announcement") {
		imgType = AnnouncementType;
	} else if (type === "poll") {
		imgType = PollType;
	}
	if (imgType !== "none") {
		return (
			<span>
				<img src={imgType} class={`card-type ${size}`} alt={type} />
			</span>
		);
	} else {
		return <div></div>;
	}
}
