// File: First view to change your password, enter your new password on this screen
// Default
import React, { useState } from "react";
// Nav
import { useNavigate } from "react-router-dom";
// API
import usersApi from "../../api/user/main";
// Libraries
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	useToast,
} from "@chakra-ui/react";

export default function ForgotPassword(props) {
	// Loading
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	// Nav
	const navigate = useNavigate();
	// Specific
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	// Requests
	const forgotPasswordCachePhone = async () => {
		setLoading(true);
		const result = await usersApi.auth.forgotPasswordCachePhone({
			phone,
			password,
		});
		if (result.ok) {
			if (result.data === "Success") {
				navigate("/forgotPassword/verify", { state: phone });
			} else {
				toast({
					title: "Error.",
					description: result.data,
					status: "error",
					duration: 5000,
					isClosable: true,
					position: "top",
				});
			}
		}
		setLoading(false);
	};

	return (
		<div id='app-center' className='forgot-password'>
			<h2 className='center'>Reset Your Password</h2>
			<FormControl>
				<FormLabel>Phone Number</FormLabel>
				<Input
					onChange={(e) => {
						setPhone(e.target.value);
					}}
					value={phone}
					placeholder='1235551234'
				/>
				<FormLabel>New Password</FormLabel>
				<Input
					onChange={(e) => {
						setPassword(e.target.value);
					}}
					value={password}
					placeholder='New Password'
				/>
				<FormLabel>Confirm New Password</FormLabel>
				<Input
					onChange={(e) => {
						setConfirmPassword(e.target.value);
					}}
					value={confirmPassword}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							if (password === confirmPassword && password.length > 0) {
								forgotPasswordCachePhone();
							} else {
								toast({
									title: "Error.",
									description: "Passwords do not match",
									status: "error",
									duration: 5000,
									isClosable: true,
									position: "top",
								});
							}
						}
					}}
					placeholder='Same password as above'
				/>
				<p className='center mt'>
					We'll send you an email with a secret code to confirm that it's you
				</p>
				<Button
					onClick={() => {
						if (password === confirmPassword) {
							forgotPasswordCachePhone();
						} else {
							toast({
								title: "Error.",
								description: "Passwords do not match",
								status: "error",
								duration: 5000,
								isClosable: true,
								position: "top",
							});
						}
					}}
					variant='contained'
					isLoading={loading}
				>
					Continue
				</Button>
			</FormControl>
		</div>
	);
}
