// File: General Home Feed for all posts
// Default
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// API
import postsApi from "../../api/posts/main";
// Components
import FeedCard from "../../components/cards/feedCard";
import HomeLeft from "../../components/initial/lefts/homeLeft";
import HomeRight from "../../components/initial/rights/homeRight";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";

export default function HomeFeed() {
	// Loading
	const [loading, setLoading] = useState(false);
	const [bottomLoading, setBottomLoading] = useState(false);
	const [skip, setSkip] = useState(0);
	const [finished, setFinished] = useState(false);

	// Specific
	const [posts, setPosts] = useState([]);
	// Requests
	const allFeed = async (fromBot) => {
		if (fromBot ? !finished : true) {
			async function getSkip() {
				if (fromBot === true) {
					let tempSkip = skip;
					setSkip(tempSkip + 1);
					setBottomLoading(true);
					return tempSkip + 1;
				} else {
					setPosts([]);
					setSkip(0);
					setLoading(true);
					return 0;
				}
			}
			if (fromBot ? !loading && !bottomLoading : !loading) {
				await getSkip().then(async (data) => {
					const result = await postsApi.newFeeds.allFeed(data);
					if (result.ok) {
						if (fromBot) {
							setPosts([...posts, ...result.data]);
							if (result.data.length === 0) {
								setFinished(true);
							} else {
								setFinished(false);
							}
						} else {
							setPosts(result.data);
						}
					}
					setLoading(false);
					setBottomLoading(false);
				});
			}
		}
	};
	//  UseEffects
	useEffect(() => {
		allFeed();
	}, []);
	return (
		<>
			<div id='app-left'>
				<HomeLeft />
			</div>
			<div id='app-right'>
				<HomeRight />
			</div>
			<div id='app-center'>
				<LoadingWrapper loading={loading}>
					<InfiniteScroll
						dataLength={posts.length}
						next={() => {
							allFeed(true);
						}}
						hasMore={!finished}
						loader={
							<LoadingWrapper loading={true}>
								<></>
							</LoadingWrapper>
						}
						height={"100vh"}
						className='home-feed'
						endMessage={
							<p style={{ textAlign: "center" }}>
								<b>Congrats! You've reached the end of the feed</b>
							</p>
						}
					>
						{posts.map(function (item, index) {
							return <FeedCard item={item} />;
						})}
					</InfiniteScroll>
				</LoadingWrapper>
			</div>
		</>
	);
}
