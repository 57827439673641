// File: Navigate back and forward
// Default
import React from "react";
// Nav
import { useNavigate } from "react-router-dom";
// Assets
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// Theming
import "../../../styles/core/left/_historyButtons.scss";
// Libraries
import { IconButton } from "@chakra-ui/react";

export default function HistoryButtons() {
	// Nav
	const navigate = useNavigate();

	return (
		<div>
			<IconButton
				onClick={() => {
					navigate(-1);
				}}
				variant='filled'
			>
				<ArrowBackIcon />
			</IconButton>
			<IconButton
				onClick={() => {
					navigate(1);
				}}
				variant='filled'
			>
				<ArrowForwardIcon />
			</IconButton>
		</div>
	);
}
