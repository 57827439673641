import client from "../client";
const endpoint = "/default/search";

const searchMembers = ({ searchField, communityID }) =>
	client.post(`${endpoint}/searchMembers`, { searchField, communityID });
const posts = ({ searchField, skip }) =>
	client.post(`${endpoint}/posts`, { searchField, skip });
const feed = {
	searchMembers,
	posts,
};
export default feed;
