// File: Second layer main file
// Default
import React, { useEffect, useContext } from "react";
import { Context } from "./providers/Provider";
// Nav
import { BrowserRouter } from "react-router-dom";
// Components
import InitialRouter from "./routes/initialRouter";
// Libraries
import jwt_decode from "jwt-decode";
import { useColorMode } from "@chakra-ui/react";

function Root() {
	// Default
	const { colorMode } = useColorMode();
	const { setUserID, setLoggedIn } = useContext(Context);
	// Functions
	const getAuthToken = async () => {
		let token = await localStorage.getItem("token");
		if (token) {
			setUserID(jwt_decode(token)._id);
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
			setUserID("");
		}
	};
	// Use Effects
	useEffect(() => {
		getAuthToken();
	}, []);

	return (
		<div
			className={
				"theme " + (colorMode === "dark" ? "theme--dark" : "theme--default")
			}
		>
			<div id='root-wrapper'>
				<BrowserRouter>
					<InitialRouter />
				</BrowserRouter>
			</div>
		</div>
	);
}
export default Root;
