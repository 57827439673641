// File: Quote for a proposal
// Default
import React from "react";
// Components
import ParagraphText from "./paragraphText";

export default function Quote({ text, caption }) {
	return (
		<div className='quote'>
			<p>
				<i>
					<ParagraphText text={`"` + text + `"`} />
				</i>
			</p>
			<p>
				<ParagraphText text={caption} />
			</p>
		</div>
	);
}
