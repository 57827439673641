// File: Edit your profile
// Default
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../providers/Provider.jsx";
// API
import usersApi from "../../api/user/main.js";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper.js";
// Components
import UploadPicture from "../uploadPicture.js";
// Libraries

import {
	Avatar,
	Button,
	ButtonGroup,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftAddon,
	useToast,
} from "@chakra-ui/react";

export default function ProfileSettingsComponent({ profileSettingsRef }) {
	// Default
	const { userID } = useContext(Context);
	const toast = useToast();
	// Loading
	const [loading, setLoading] = useState(true);
	// Specific
	const [user, setUser] = useState({
		name: "",
		profilePic: "",
		userID: "",
		username: "",
	});
	const [newName, setNewName] = useState(null);
	const [newUsername, setNewUsername] = useState(null);
	const [newBio, setNewBio] = useState(null);

	const [editing, setEditing] = useState(false);
	const [newPic, setNewPic] = useState(null);
	// Requests
	const uploadName = async () => {
		const result = await usersApi.profile.uploadName(newName);
		if (!result.ok) {
		} else {
			setUser({ ...user, ...{ name: newName } });

			setEditing(false);
		}
	};
	const uploadUsername = async () => {
		const result = await usersApi.profile.uploadUsername(newUsername);
		if (!result.ok) {
		} else {
			setUser({ ...user, ...{ username: newUsername } });
			setEditing(false);
		}
	};
	const uploadBio = async () => {
		const result = await usersApi.profile.uploadBio(newBio);
		if (!result.ok) {
		} else {
			setUser({ ...user, ...{ bio: newBio } });
			setEditing(false);
		}
	};
	const uploadPic = async (formData) => {
		const result = await usersApi.profile.uploadProfilePic(formData);
		if (result.ok) {
			toast({
				title: "Image Uploaded.",
				description: "It will take a few moments to update.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};
	const getUser = async () => {
		setLoading(true);
		const result = await usersApi.data.profileInfo(userID);
		if (!result.ok) {
		} else {
			let user = {
				name: result.data.name,
				profilePic: result.data.profilePic,
				userID: result.data._id,
				username: result.data.username,
				bio: result.data.bio,
			};
			setNewName(result.data.name);
			setNewUsername(result.data.username);
			setNewBio(result.data.bio);
			setUser(user);
		}
		setLoading(false);
	};
	// Functions
	const submitImg = async () => {
		if (newPic) {
			const formData = new FormData();
			formData.append("photo", newPic.image);

			await uploadPic(formData);
		}
	};
	// Use Effects
	useEffect(() => {
		getUser();
	}, [userID]);
	return (
		<div
			className='settings section-card'
			id='profile-settings-component'
			ref={profileSettingsRef}
		>
			<LoadingWrapper loading={loading}>
				{!editing ? (
					<>
						<Button
							variant='outlined'
							size='sm'
							onClick={() => {
								setEditing(true);
							}}
						>
							Edit Profile
						</Button>
						<div className='header profile'>
							<div className='avatar-container'>
								<Avatar
									alt={user.name}
									src={user.profilePic}
									className='avatar'
								/>
							</div>
							<h2 className='center'>{user.name}</h2>
							<h3 className='center secondary'>@{user.username}</h3>
							<p>{user.bio}</p>
						</div>
					</>
				) : (
					<div>
						<ButtonGroup>
							<Button
								variant='outlined'
								onClick={() => {
									setNewName(user.name);

									setEditing(false);
								}}
							>
								Cancel
							</Button>
							<Button
								variant='contained'
								onClick={() => {
									setEditing(false);
									if (newPic) {
										submitImg();
									}
									if (user.name !== newName) {
										uploadName();
									}
									if (user.username !== newUsername) {
										uploadUsername();
									}
									if (user.bio !== newBio) {
										uploadBio();
									}
								}}
							>
								Save And Exit
							</Button>
						</ButtonGroup>
						<div className='header'>
							<UploadPicture
								uploadPic={uploadPic}
								currentPic={user.profilePic}
								newPic={newPic}
								setNewPic={setNewPic}
							/>
							<FormControl>
								<FormLabel>Name</FormLabel>
								<Input
									label={"New Name"}
									onChange={(e) => {
										setNewName(e.target.value);
									}}
									value={newName}
									fullWidth
								/>
								<FormLabel>Username</FormLabel>
								<InputGroup>
									<InputLeftAddon children='@' />
									<Input
										label={"somethingCool"}
										onChange={(e) => {
											setNewUsername(e.target.value);
										}}
										value={newUsername}
										fullWidth
									/>
								</InputGroup>
								<FormLabel>Bio</FormLabel>

								<InputGroup>
									<Input
										label={"Some info about you"}
										onChange={(e) => {
											setNewBio(e.target.value);
										}}
										value={newBio}
										fullWidth
									/>
								</InputGroup>
							</FormControl>
						</div>
					</div>
				)}
			</LoadingWrapper>
		</div>
	);
}
