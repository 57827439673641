// File: Get discussions under a post
// Default
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../providers/Provider";
// API
import postsApi from "../api/posts/main";
// Components
import DiscussionCard from "./cards/discussionCard";
// Assets
import { Send } from "@material-ui/icons";
// Libraries
import {
	Button,
	Input,
	InputGroup,
	InputRightElement,
	useToast,
} from "@chakra-ui/react";
import LoadingWrapper from "../helper/loadingWrapper";

export default function Discussions({ identifier, type, canDiscuss }) {
	// Default
	const { loggedIn } = useContext(Context);
	const toast = useToast();
	// Loading
	const [posting, setPosting] = useState(false);
	const [loading, setLoading] = useState(false);
	// Specific
	const [message, setMessage] = useState("");
	const [discussions, setDiscussions] = useState([]);
	// Requests
	const getDiscussions = async () => {
		setLoading(true);
		const result = await postsApi.newFeeds.discussions({
			identifier,
			parentType: type,
		});
		if (!result.ok) {
		} else {
			setDiscussions(result.data);
		}
		setLoading(false);
	};

	const postDiscussion = async () => {
		setPosting(true);
		const result = await postsApi.discussion.create.post({
			message,
			parentType: type,
			identifier,
		});
		if (result.ok) {
			getDiscussions();
			setMessage("");
		}
		setPosting(false);
	};
	// Use Effects
	useEffect(() => {
		getDiscussions();
	}, []);

	return (
		<div className='discussions'>
			<h5 className='left pt-L'>Discussion</h5>
			{canDiscuss && (
				<div className='text-field-wrapper messages'>
					<InputGroup>
						<Input
							value={message}
							onChange={(e) => {
								setMessage(e.target.value);
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									if (loggedIn) {
										postDiscussion();
									} else {
										toast({
											title: "Sorry!",
											description: "You must have an account to send messages.",
											status: "error",
											duration: 5000,
											isClosable: true,
											position: "top",
										});
									}
								}
							}}
							placeholder={"Send a message..."}
						/>
						<InputRightElement>
							<Button
								variant='contained'
								disabled={message.length === 0}
								onClick={() => {
									if (loggedIn) {
										postDiscussion();
									} else {
										toast({
											title: "Sorry!",
											description: "You must have an account to send messages.",
											status: "error",
											duration: 5000,
											isClosable: true,
											position: "top",
										});
									}
								}}
								isLoading={posting}
							>
								<Send className='icon-white' />
							</Button>
						</InputRightElement>
					</InputGroup>
				</div>
			)}
			<LoadingWrapper loading={loading}>
				{discussions.length === 0 && (
					<div className='std-card'>
						<p className='bold center'>No messages yet</p>
						<p className='center'>Be the first to send one!</p>
					</div>
				)}
				{discussions.map(function (item, idx) {
					return (
						<li key={idx}>
							<DiscussionCard item={item} />
						</li>
					);
				})}
			</LoadingWrapper>
		</div>
	);
}
