// File: Main file
// Default
import React from "react";
// Components
import { Provider } from "./providers/Provider";
import Root from "./root";
// Theming
import "./styles/index.scss";
import chakraTheme from "./styles/chakraTheme";
// Libraries
import { Toaster } from "react-hot-toast";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
	return (
		<Provider>
			<Toaster
				position='top-center'
				reverseOrder={false}
				gutter={8}
				containerClassName=''
				containerStyle={{}}
				toastOptions={{
					className: "toaster",
					duration: 5000,
					style: {},
				}}
			/>
			<ChakraProvider theme={chakraTheme}>
				<Root />
			</ChakraProvider>
		</Provider>
	);
}
export default App;
