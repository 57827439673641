// File: User information component
// Default
import React, { useContext, useState } from "react";
import { Context } from "../../../providers/Provider";
// API
import usersApi from "../../../api/user/main";
// Libraries

import {
	Avatar,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	useToast,
	Button,
} from "@chakra-ui/react";
import { Block, Edit, MoreVert, Report, Share } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Linkify from "react-linkify";
export default function UserContent({
	profileInfo,
	statsInfo,
	yourUserStatesInfo,
}) {
	// Default
	const { userID, loggedIn } = useContext(Context);
	// Nav
	const toast = useToast();
	const navigate = useNavigate();
	// Specific
	const [blocked, setBlocked] = useState(yourUserStatesInfo.isBlocked);
	const [supported, setSupported] = useState(yourUserStatesInfo.isSupported);
	// Requests
	const blockUser = async () => {
		const result = await usersApi.support.blockUser({
			userID: profileInfo.userID,
			isOrg: profileInfo.isOrg,
		});
		if (result.ok) {
			setBlocked(true);
		}
	};
	const unBlockUser = async () => {
		const result = await usersApi.support.unBlockUser({
			userID: profileInfo.userID,
			isOrg: profileInfo.isOrg,
		});
		if (result.ok) {
			setBlocked(false);
		}
	};
	const supportUser = async () => {
		const result = await usersApi.support.supportUser({
			userID: profileInfo.userID,
			isOrg: profileInfo.isOrg,
		});
		if (result.ok) {
			setSupported(true);
		}
	};
	const unSupportUser = async () => {
		const result = await usersApi.support.unSupportUser({
			userID: profileInfo.userID,
			isOrg: profileInfo.isOrg,
		});
		if (result.ok) {
			setSupported(false);
		}
	};
	const blockHandler = () => {
		if (!blocked) {
			blockUser();
		} else {
			unBlockUser();
		}
	};
	const shareHandler = () => {
		navigator.clipboard.writeText(
			window.location.origin +
				`/profile/${profileInfo.userID}/${
					profileInfo.isOrg !== undefined ? profileInfo.isOrg : "false"
				}`
		);
		toast({
			title: "Copied to clipboard.",
			description: "Share the link with friends!",
			status: "info",
			duration: 5000,
			isClosable: true,
			position: "top",
		});
	};

	// Popover
	const PopOverButton = ({ text, icon, clickHandler }) => {
		return (
			<button
				className='card-list-item'
				onClick={(e) => {
					e.stopPropagation();
					clickHandler();
				}}
			>
				{icon}
				<p>{text}</p>
			</button>
		);
	};
	const Options = () => {
		return (
			<div>
				<Popover>
					<PopoverTrigger>
						<button
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<MoreVert />
						</button>
					</PopoverTrigger>
					<Portal>
						<PopoverContent>
							<PopoverArrow />
							<PopoverHeader>User Options</PopoverHeader>
							<PopoverBody>
								<div>
									<PopOverButton
										text={"Share Profile"}
										icon={<Share />}
										clickHandler={shareHandler}
									/>
									{profileInfo.userID !== userID && loggedIn && (
										<PopOverButton
											text={!blocked ? "Block User" : "Unblock User"}
											icon={<Block />}
											clickHandler={blockHandler}
										/>
									)}
								</div>
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			</div>
		);
	};
	return (
		<div id='profile-card'>
			<div className='options'>
				<Options />
			</div>
			<Avatar src={profileInfo.pic} className='avatar' />
			<h3 className='center'>{profileInfo.name}</h3>
			<p>@{profileInfo.username || "user" + profileInfo.userID}</p>

			{profileInfo.userID === userID ? (
				<Button
					variant='outlined'
					isFullWidth
					leftIcon={<Edit />}
					onClick={() => navigate("/settings/profile")}
				>
					Edit Profile
				</Button>
			) : (
				<Button
					onClick={() => {
						if (supported) {
							unSupportUser();
						} else {
							supportUser();
						}
					}}
					isFullWidth
					disabled={!loggedIn}
					variant={!supported ? "contained" : "outlined"}
				>
					{!supported ? "Support" : "Supporting"}
				</Button>
			)}
			<Linkify>
				<p>{profileInfo.bio || ""}</p>
			</Linkify>

			<div className='info'>
				<div className='stat'>
					<h6>{statsInfo.posts} </h6>
					<h6>posts</h6>
				</div>

				<div className='stat'>
					<h6>{statsInfo.supporters} </h6>
					<h6>supporters</h6>
				</div>
			</div>
		</div>
	);
}
