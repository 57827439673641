// File: Header for a proposal

export default function Header({ text, level }) {
	return (
		<div>
			{level === 1 && (
				<div
					dangerouslySetInnerHTML={{
						__html: "<h1>" + text + "</h1>",
					}}
				/>
			)}
			{level === 2 && (
				<div
					dangerouslySetInnerHTML={{
						__html: "<h2>" + text + "</h2>",
					}}
				/>
			)}
			{level === 3 && (
				<div
					dangerouslySetInnerHTML={{
						__html: "<h3>" + text + "</h3>",
					}}
				/>
			)}
			{level === 4 && (
				<div
					dangerouslySetInnerHTML={{
						__html: "<h4>" + text + "</h4>",
					}}
				/>
			)}
			{level === 5 && (
				<div
					dangerouslySetInnerHTML={{
						__html: "<h5>" + text + "</h5>",
					}}
				/>
			)}
			{level === 6 && (
				<div
					dangerouslySetInnerHTML={{
						__html: "<h6>" + text + "</h6>",
					}}
				/>
			)}
		</div>
	);
}
