// File: Paragraph text for a proposal
// Default
import React from "react";

export default function ParagraphText({ text }) {
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: "<p>" + text + "</p>",
			}}
		/>
	);
}
