import client from "../../client";
const endpoint = "/posts/discussion/create";

const post = ({ message, identifier, createdAt, parentType }) =>
	client.post(`${endpoint}/post`, {
		message,
		identifier,
		createdAt,
		parentType,
	});

const create = { post };
export default create;
