// File: Adding emails to your account
// Default
import React, { useState, useEffect } from "react";
// API
import usersApi from "../../api/user/main";
// Helper
import LoadingWrapper from "../../helper/loadingWrapper";
// Assets
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import { Close, Mail } from "@material-ui/icons";
// Libraries
import {
	Button,
	ButtonGroup,
	Input,
	InputGroup,
	InputRightElement,
	Tooltip,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	useToast,
} from "@chakra-ui/react";

export default function AddEmails({ emailRef }) {
	// Loading
	const [loading, setLoading] = useState(true);
	const toast = useToast();
	// Specific
	const [addingEmail, setAddingEmail] = useState(false);
	const [emails, setEmails] = useState([]);
	const [email, setEmail] = useState("");
	// Requests
	const addEmail = async () => {
		const result = await usersApi.profile.addEmail(email);
		if (!result.ok) {
		} else {
			setAddingEmail(false);
			toast({
				title: "Email Added!",
				description: "Check you email for your verification link.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};
	const deleteEmail = async (email) => {
		const result = await usersApi.profile.deleteEmail(email);
		if (!result.ok) {
		} else {
			getEmails();
		}
	};
	const resendEmail = async (email) => {
		const result = await usersApi.profile.resendEmail(email);
		if (!result.ok) {
		} else {
			toast({
				title: "Email Sent!",
				description: "Check you email for your verification link.",
				status: "info",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
	};

	const getEmails = async () => {
		setLoading(true);
		const result = await usersApi.profile.getEmails();
		if (!result.ok) {
		} else {
			setEmails(result.data);
		}
		setLoading(false);
	};
	// Use Effects
	useEffect(() => {
		getEmails();
	}, []);

	return (
		<div className='section-card' id='add-emails' ref={emailRef}>
			<div className='header'>
				<h3>Emails</h3>
				{!addingEmail && (
					<Button
						onClick={() => {
							setAddingEmail(!addingEmail);
						}}
						className='right'
						variant='outlined'
						leftIcon={<Mail />}
					>
						Add Email
					</Button>
				)}
				{addingEmail && (
					<Button
						onClick={() => {
							setEmail("");
							setAddingEmail(false);
						}}
						className='right'
						variant='outlined'
						leftIcon={<Close />}
					>
						Cancel
					</Button>
				)}
			</div>
			{addingEmail && (
				<div>
					<p>Add additional email (work, school, etc)</p>
					<Input
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						value={email}
						placeholder='Email'
					/>
					<Button
						className='mt'
						onClick={() => {
							let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
							if (email.match(regexEmail)) {
								addEmail();
							}
						}}
						disabled={email.length === 0}
						isFullWidth
						variant='contained'
					>
						Confirm
					</Button>
				</div>
			)}

			<LoadingWrapper loading={loading}>
				<div className='section-content'>
					{emails.length === 0 ? (
						<div className='center'>
							<p>
								Use domain specific emails to gain access to more Quae
								Communities
							</p>
							<Button
								onClick={() => {
									setAddingEmail(!addingEmail);
								}}
								className='right'
								variant='contained'
								rightIcon={<AddIcon className='icon-white' />}
							>
								Add One Today
							</Button>
						</div>
					) : (
						<Accordion allowToggle>
							<div>
								{emails.map(function (d, idx) {
									return (
										<AccordionItem key={idx}>
											<AccordionButton>
												<div className='stat-icon'>
													{!d.verified && <FmdBadIcon className='danger' />}
													{d.verified && (
														<CheckCircleIcon className='success' />
													)}
													<p>{d.email}</p>
												</div>
												<AccordionIcon />
											</AccordionButton>
											<AccordionPanel>
												{d.verified && <p>This email is verified</p>}
												<ButtonGroup>
													{!d.verified && (
														<Tooltip label="Please verify your new email so we know it's yours.">
															<Button
																onClick={() => {
																	resendEmail(d.email);
																}}
																size='sm'
																variant='contained'
															>
																Resend Email
															</Button>
														</Tooltip>
													)}

													<Tooltip label='This action in permanent'>
														<Button
															onClick={() => {
																//TODO:
																deleteEmail(d.email);
															}}
															size='sm'
															variant='danger'
														>
															Delete Email
														</Button>
													</Tooltip>
												</ButtonGroup>
											</AccordionPanel>
										</AccordionItem>
									);
								})}
							</div>
						</Accordion>
					)}
				</div>
			</LoadingWrapper>
		</div>
	);
}
