import { create } from "apisauce";
import authStorage from "../auth/storage";

const apiClient = create({
	baseURL: "https://quaeapplication.herokuapp.com/",
	// baseURL: "http://localhost:4000/",
});

apiClient.addAsyncRequestTransform(async (request) => {
	const authToken = await authStorage.getToken();
	if (!authToken) return;
	request.headers["authorization"] = "Bearer " + authToken;
});

export default apiClient;
