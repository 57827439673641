// File: Card options for poll
// Default
import React, { useState, useEffect } from "react";

export default function CardOption({
	optionText,
	votes,
	voted,
	decision,
	totalVotes,
	onClick,
	disabled,
	multiDecisions,
	optionsType,
	showDecision,
}) {
	// Specific
	const [selected, setSelected] = useState(false);
	const [barWidth, setBarWidth] = useState("0%");
	// Use Effects
	useEffect(() => {
		setBarWidth(`${(100 * votes) / totalVotes}%`);
	}, [votes, totalVotes]);

	useEffect(() => {
		if (optionsType === "Multi") {
			if (multiDecisions.find((obj) => obj.option === optionText)) {
				console.log("selected to true");

				setSelected(true);
			} else {
				console.log("selected to false");

				setSelected(false);
			}
		} else {
			if (decision === optionText) {
				setSelected(true);
			} else {
				setSelected(false);
			}
		}
	}, [decision, multiDecisions]);

	return (
		<button
			className={`option  ${voted ? "voted " : "not-voted"} ${
				voted
					? selected && showDecision
						? "selected"
						: ""
					: selected
					? "selected"
					: ""
			}`}
			disabled={disabled || voted}
			onClick={(e) => {
				if (onClick !== null) {
					e.stopPropagation();
					onClick(optionText);
				}
			}}
		>
			<p className='option-text'>{optionText}</p>
			<div className='result'>{voted ? <h6>{votes}</h6> : <h6> </h6>}</div>

			<div className='percent-bar'></div>
		</button>
	);
}
