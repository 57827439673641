// File: User auth signing up

// Default
import React, { useState } from "react";

// Nav
import { useNavigate } from "react-router-dom";

// API
import usersApi from "../../api/user/main";

// Libraries

import {
	Button,
	FormControl,
	FormLabel,
	Input,
	useToast,
} from "@chakra-ui/react";

export default function SignUp(props) {
	// Default
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	// Nav
	const navigate = useNavigate();
	// Specific
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	// Requests
	const signUp = async () => {
		setLoading(true);
		const result = await usersApi.auth.cachePhone({
			phone,
			password,
		});

		if (result.ok) {
			if (result.data === "Success") {
				navigate("/verify", { state: phone });
			} else {
				toast({
					title: "Error.",
					description: result.data,
					status: "error",
					duration: 5000,
					isClosable: true,
					position: "top",
				});
			}
		}
		setLoading(false);
	};

	return (
		<div id='app-center' className='sign-up'>
			<h2 className='center'>Sign Up</h2>
			<FormControl>
				<FormLabel>Phone Number</FormLabel>
				<Input
					onChange={(e) => {
						setPhone(e.target.value);
					}}
					value={phone}
					autoFocus
					placeholder='1235551234'
				/>
				<FormLabel>Password</FormLabel>
				<Input
					onChange={(e) => {
						setPassword(e.target.value);
					}}
					value={password}
					placeholder='Secret1!'
				/>
				<FormLabel>Confirm Password</FormLabel>
				<Input
					onChange={(e) => {
						setConfirmPassword(e.target.value);
					}}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							if (password === confirmPassword) {
								signUp();
							} else {
								toast({
									title: "Error.",
									description: "Passwords do not match",
									status: "error",
									duration: 5000,
									isClosable: true,
									position: "top",
								});
							}
						}
					}}
					value={confirmPassword}
					placeholder='Same password as above'
				/>
				<Button
					onClick={() => {
						if (password === confirmPassword) {
							signUp();
						} else {
							toast({
								title: "Error.",
								description: "Passwords do not match",
								status: "error",
								duration: 5000,
								isClosable: true,
								position: "top",
							});
						}
					}}
					disabled={
						phone.length === 0 ||
						password.length === 0 ||
						confirmPassword.length === 0
					}
					variant='contained'
					isLoading={loading}
				>
					Sign Up
				</Button>
			</FormControl>

			<div className='pt'>
				<p className='center'>
					Have an account? <a href='/login'>Log In</a>
				</p>
			</div>
			{loading && (
				<div className='loading-wheel-center-container'>
					<div className='loading-wheel' />
				</div>
			)}
		</div>
	);
}
