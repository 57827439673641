// File: List for a proposal
// Default
import React from "react";
// Components
import ParagraphText from "./paragraphText";

export default function List({ items, style, level, parentIndex }) {
	return (
		<div className='list'>
			{items.map(function (d, idx) {
				return (
					<div>
						<div style={{ display: "flex" }}>
							{style === "unordered" && <p style={{ paddingRight: 10 }}>-</p>}
							{style === "ordered" && (
								<p style={{ paddingRight: 10 }}>
									{level === 1
										? idx + 1 + "."
										: parentIndex + 1 + "." + (idx + 1) + "."}
								</p>
							)}

							<ParagraphText text={d.content} />
						</div>
						{d.items.length > 0 && (
							<div style={{ paddingLeft: 10 }}>
								<List
									items={d.items}
									style={style}
									level={level + 1}
									parentIndex={idx}
								/>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
}
