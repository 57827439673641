import client from "../client";
const endpoint = "/community/feed";

const leftGroups = () => client.post(`${endpoint}/leftGroups`);

const postAsGroups = () => client.post(`${endpoint}/postAsGroups`);

const rightGroups = () => client.post(`${endpoint}/rightGroups`);

const searchPublicGroups = (searchField) =>
	client.post(`${endpoint}/searchPublicGroups`, { searchField });

const suggestedGroups = () => client.post(`${endpoint}/suggestedGroups`);

const invitedGroups = () => client.post(`${endpoint}/invitedGroups`);

const feed = {
	leftGroups,
	rightGroups,
	searchPublicGroups,
	suggestedGroups,
	invitedGroups,
	postAsGroups,
};
export default feed;
