// File: Reusable loading wrapper
// Default
import React from "react";
// Theming
import themeStyle from "../styles/theme.style";
// Libraries
import { DotSpinner, RaceBy, Ripples, Waveform } from "@uiball/loaders";

export default function LoadingWrapper({ loading, children, type }) {
	if (loading) {
		return (
			<div className='loading-container'>
				{(!type || type === "wave") && (
					<Waveform className='loader' color={themeStyle.COLOR_PRIMARY_LIGHT} />
				)}
				{type === "bar" && (
					<RaceBy className='loader' color={themeStyle.COLOR_PRIMARY_LIGHT} />
				)}
				{type === "ripples" && (
					<Ripples className='loader' color={themeStyle.COLOR_PRIMARY_LIGHT} />
				)}
				{type === "dot-spinner" && (
					<DotSpinner
						className='loader'
						color={themeStyle.COLOR_PRIMARY_LIGHT}
					/>
				)}
			</div>
		);
	} else return children;
}
