import client from "../client";
const endpoint = "/user/data";

const profileContent = ({ userID, isOrg, communityID }) =>
	client.post(`${endpoint}/profileContent`, { userID, isOrg, communityID });

const profileInfo = (userID) => client.get(`${endpoint}/profileInfo/${userID}`);

const notifications = () => client.post(`${endpoint}/notifications`);

const blockedUsers = () => client.post(`${endpoint}/blockedUsers`);

const supportingUsers = () => client.post(`${endpoint}/supportingUsers`);

const clearNotifications = () => client.post(`${endpoint}/clearNotifications`);

const deleteNotification = (notificationIndex) =>
	client.post(`${endpoint}/deleteNotification`, { notificationIndex });

const data = {
	profileContent,
	profileInfo,
	notifications,
	clearNotifications,
	deleteNotification,
	blockedUsers,
	supportingUsers,
};
export default data;
