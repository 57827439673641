// File: Verifying the code received via text to change password
// Default
import React, { useState, useContext } from "react";
import { Context } from "../../providers/Provider";
// Nav
import { useLocation, useNavigate } from "react-router-dom";
// API
import usersApi from "../../api/user/main";
// Libraries
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	useToast,
} from "@chakra-ui/react";

export default function ForgotPasswordVerify(props) {
	// Default
	const [loading, setLoading] = useState(false);
	const { setLoggedIn, setUserID } = useContext(Context);
	const toast = useToast();
	// Nav
	const navigate = useNavigate();
	const location = useLocation();

	// Specific
	const [code, setCode] = useState("");

	// Requests
	const forgotPasswordVerifyCode = async () => {
		setLoading(true);
		const result = await usersApi.auth.forgotPasswordVerifyCode({
			phone: location.state,
			code,
		});

		if (result.ok) {
			const data = result.data;
			if (data.token) {
				await localStorage.setItem("token", result.data.token);
				setLoggedIn(true);
				setUserID(result.data.userID);
				navigate("/");
			} else {
				toast({
					title: "Error.",
					description: result.data,
					status: "error",
					duration: 5000,
					isClosable: true,
					position: "top",
				});
			}
		}
	};

	return (
		<div id='app-center' className='forgot-password-verify'>
			<h3 className='center'>Reset Your Password</h3>
			<FormControl>
				<FormLabel>Enter the code you got in your email</FormLabel>
				<Input
					onChange={(e) => {
						setCode(e.target.value);
					}}
					value={code}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							if (code.length === 7) {
								forgotPasswordVerifyCode();
							} else {
								toast({
									title: "Error.",
									description: "Invalid Code",
									status: "error",
									duration: 5000,
									isClosable: true,
									position: "top",
								});
							}
						}
					}}
					placeholder='Enter code...'
				/>

				<Button
					onClick={() => {
						if (code.length === 7) {
							forgotPasswordVerifyCode();
						} else {
							toast({
								title: "Error.",
								description: "Invalid Code",
								status: "error",
								duration: 5000,
								isClosable: true,
								position: "top",
							});
						}
					}}
					variant='contained'
					isLoading={loading}
				>
					Confirm
				</Button>
			</FormControl>
		</div>
	);
}
