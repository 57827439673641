import client from "../client";
const endpoint = "/user/support";

const supportUser = ({ userID, isOrg }) =>
	client.post(`${endpoint}/supportUser`, { userID, isOrg });

const unSupportUser = ({ userID, isOrg }) =>
	client.post(`${endpoint}/unSupportUser`, { userID, isOrg });

const blockUser = ({ userID, isOrg }) =>
	client.post(`${endpoint}/blockUser`, { userID, isOrg });

const unBlockUser = ({ userID, isOrg }) =>
	client.post(`${endpoint}/unBlockUser`, { userID, isOrg });

const support = {
	supportUser,
	unSupportUser,
	blockUser,
	unBlockUser,
};
export default support;
