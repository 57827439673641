// File: Left margin of home feed, shows important nav buttons and your communities
// Default
import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../providers/Provider";
// Nav
import { useNavigate } from "react-router-dom";
// API
import communityApi from "../../../api/community/main";
// Helper
import LoadingWrapper from "../../../helper/loadingWrapper";
// Components
import BeHeardButton from "../beHeardButton";
import CommunityCard from "../../cards/communityCard";
// Assets
import QuaeLogo from "../../../assets/images/quae_app_icon_gradient_square.png";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
// Libraries
import { Button, useToast } from "@chakra-ui/react";

export default function HomeLeft(props) {
	// Default
	const toast = useToast();
	const { userID, loggedIn } = useContext(Context);
	// Loading
	const [loading, setLoading] = useState(true);
	// Nav
	const navigate = useNavigate();
	// Specific
	const [favorites, setFavorites] = useState([]);
	const [yourGroups, setYourGroups] = useState([]);
	const [yourGroupsExpanded, setYourGroupsExpanded] = useState(false);
	// Requests
	const getDrawerGroups = async () => {
		setLoading(true);
		const result = await communityApi.feed.leftGroups();
		if (result.ok) {
			setYourGroups(result.data.yourGroups);
			setFavorites(result.data.favorites);
		}
		setLoading(false);
	};
	// Functions
	function selectGroup(groupID) {
		navigate(`/feed/${groupID}`);
	}
	// Use Effects
	useEffect(() => {
		if (loggedIn) {
			getDrawerGroups();
		} else {
			setLoading(false);
		}
	}, [userID, loggedIn]);

	return (
		<div className={props.mobile ? "" : "section-card"}>
			<div className='visible-mobile w-full'>
				{/* <div className="drawer-btn">
					<button
						className="header-btn"
						onClick={() => {
							navigate("/settings");
						}}>
						<Settings />
					</button>
					<h6>Settings</h6>
				</div> */}
			</div>
			{!props.mobile && <BeHeardButton />}
			<Button
				className='mt'
				onClick={() => {
					if (loggedIn) {
						navigate("/community/join");
					} else {
						toast({
							title: "Sorry!",
							description:
								"You Must Be Logged In To Join or Create a Community",
							status: "error",
							duration: 5000,
							isClosable: true,
							position: "top",
						});
					}
				}}
				variant='outlined'
				isFullWidth
			>
				Join/Create Communities
			</Button>
			<LoadingWrapper loading={loading}>
				<div className='group-list-container scroll-auto'>
					<CommunityCard
						groupID={"Global"}
						name={"Global Feed"}
						pic={QuaeLogo}
						subGroups={[]}
						isAdmin={false}
						onClick={(groupID) => {
							selectGroup(groupID);
						}}
						fromLeft
					/>
					{loggedIn && (
						<>
							<h5>Your Favorites</h5>
							{favorites && favorites.length > 0 ? (
								<>
									{favorites.map(function (item, index) {
										return (
											<CommunityCard
												groupID={item._id}
												name={item.name}
												pic={item.pic}
												subGroups={item.subGroups}
												isAdmin={item.isAdmin}
												onClick={(groupID) => {
													selectGroup(groupID);
												}}
												fromLeft
												favorite
												withOptions
											/>
										);
									})}
								</>
							) : (
								<p>Add a favorite</p>
							)}
							{yourGroups.length > 0 && (
								<button
									className='flex-start'
									onClick={(e) => {
										e.stopPropagation();
										setYourGroupsExpanded(!yourGroupsExpanded);
									}}
								>
									<h5>Your Communities</h5>
									{!yourGroupsExpanded && <ArrowDropDown size='large' />}
									{yourGroupsExpanded && <ArrowDropUp size='large' />}
								</button>
							)}
							{yourGroupsExpanded && (
								<>
									{yourGroups.length > 0 ? (
										<>
											{yourGroups.map(function (item, index) {
												return (
													<CommunityCard
														groupID={item._id}
														name={item.name}
														pic={item.pic}
														subGroups={item.subGroups}
														isAdmin={item.isAdmin}
														onClick={(groupID) => {
															selectGroup(groupID);
														}}
														fromLeft
														withOptions
													/>
												);
											})}
										</>
									) : (
										<p>You're not a member of any groups</p>
									)}
								</>
							)}
						</>
					)}
				</div>
			</LoadingWrapper>
			{!loggedIn && (
				<div className='visible-mobile w-full button-group-vertical'>
					<Button
						variant='outlined'
						onClick={() => {
							navigate("/login");
						}}
					>
						Login
					</Button>
					<Button
						onClick={() => {
							navigate("/signup");
						}}
						variant='contained'
					>
						Sign Up
					</Button>
				</div>
			)}
		</div>
	);
}
